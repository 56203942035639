import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,InputAdornment
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { configCurrencies, configConciergeCategories, configCountries } from '../../api'; // Importing the API methods
import { useNotification } from '../../context/NotificationContext'; // Import the notification hook

const ConciergeForm = ({ user, apply, toggleShow }) => {
  const { t } = useTranslation();
  const [currencies, setCurrencies] = useState([]);
  const [categories, setCategories] = useState([]); // State for categories
  const [countries, setCountries] = useState([]); // State for countries
  const { showNotification } = useNotification();

  // Generate a random code for the concierge
  const generateCode = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    const length = Math.floor(Math.random() * 3) + 5; // Length between 5 and 7
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  // Initialize the form using react-hook-form
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    defaultValues: {
      name: '',
      category: '',
      contact: { phone: '', email: '' },
      address: '',
      country: '',
      city: '',
      fees: '',
      plan_expiry_date: '',
      description: '',
      notes: '',
      currency: '',
      code: generateCode() // Auto-generate the code
    },
    mode: "onChange",
  });

  // Fetch currencies and categories on component mount
  useEffect(() => {
    const fetchCurrenciesAndCategories = async () => {
      try {
        const [currencyRes, categoryRes, countriesRes] = await Promise.all([
          configCurrencies(),
          configConciergeCategories(),
          configCountries(),
        ]);
        setCurrencies(currencyRes.data);
        setCategories(categoryRes.data);
        setCountries(countriesRes.data);
      } catch (error) {
        console.error('Failed to fetch currencies or categories', error);
      }
    };
    fetchCurrenciesAndCategories();
  }, [user.currency]);

  // Handle form submission
  const onSubmit = async (data) => {
    try {
      await apply(data);
      toggleShow();
    } catch (error) {
      console.error('Failed to create concierge:', error);
      showNotification(t('failed_create_concierge') + ': ' + error.message, 'error');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('name')}
                  error={!!errors.name}
                  helperText={errors.name ? t('required') : ''}
                  required
                />
              )}
            />
          </Grid>

          {/* Dynamic Category Select */}
          <Grid item xs={12}>
            <FormControl fullWidth margin="dense">
              <InputLabel>{t('category')}</InputLabel>
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <Select {...field} label={t('category')} required>
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {t(category)} {/* Translate categories if needed */}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.category && <p>{t('required')}</p>}
            </FormControl>
          </Grid>

          {/* Phone and Email */}
          <Grid item xs={12}>
            <Controller
              name="contact.phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('phone')}
                  error={!!errors.contact?.phone}
                  helperText={errors.contact?.phone ? t('required') : ''}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="contact.email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('email')}
                  error={!!errors.contact?.email}
                  helperText={errors.contact?.email ? t('required') : ''}
                  required
                />
              )}
            />
          </Grid>

          {/* Address, Country, City */}
          <Grid item xs={12}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('address')}
                  error={!!errors.address}
                  helperText={errors.address ? t('required') : ''}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="dense">
              <InputLabel>{t('country')}</InputLabel>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select {...field} label={t('country')} required>
                    {countries.map((country) => (
                      <MenuItem key={country} value={country}>
                        {t(country)} {/* Translate categories if needed */}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.country && <p>{t('required')}</p>}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('city')}
                  error={!!errors.city}
                  helperText={errors.city ? t('required') : ''}
                />
              )}
            />
          </Grid>

          {/* Fees and Plan Expiry Date */}
          <Grid item xs={12}>
  <Controller
    name="fees"
    control={control}
    rules={{
      required: t('fees_required'),
      min: { value: 0, message: t('fees_min') }, // Custom error messages
      max: { value: 100, message: t('fees_max') },
    }}
    render={({ field }) => (
      <TextField
        {...field}
        fullWidth
        label={t('management_fees')}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        error={!!errors.fees}
      />
    )}
  />
</Grid>
          <Grid item xs={12}>
            <Controller
              name="plan_expiry_date"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('plan_expiry_date')}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.plan_expiry_date}
                  helperText={errors.plan_expiry_date ? t('required') : ''}
                  required
                />
              )}
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('description')}
                  multiline
                  rows={3}
                  error={!!errors.description}
                  helperText={errors.description ? t('description_required') : ''}
                />
              )}
            />
          </Grid>

          {/* Currency Select */}
          <Grid item xs={12}>
            <FormControl fullWidth margin="dense">
              <InputLabel>{t('currency')}</InputLabel>
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Select {...field} label={t('currency')} required>
                    {currencies.map((curr) => (
                      <MenuItem key={curr} value={curr}>{curr}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          {/* Auto-generated Code (read-only) */}
          <Grid item xs={12}>
            <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('code')}
                  error={!!errors.code}
                  helperText={errors.code ? t('code_required') : ''}
                  required
                  value={field.value}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={toggleShow} color="secondary">
          {t('cancel')}
        </Button>
        <Button color="primary" type="submit" disabled={!isValid}>
          {t('save')}
        </Button>
      </DialogActions>
    </form>
  );
};

export default ConciergeForm;
