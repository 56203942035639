import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useClient } from 'react-supabase';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Avatar,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '../../logo192.png';
import { useNotification } from '../../context/NotificationContext';

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useClient();
  const { showNotification } = useNotification();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const validationSchema = yup.object().shape({
    email: yup.string().email(t('invalidEmail')).required(t('emailRequired')),
    password: yup.string().min(6, t('passwordTooShort')).required(t('passwordRequired')),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], t('passwordsMustMatch')),
  });

  const { control, handleSubmit, setError, formState, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { errors, isSubmitting } = formState;

  const onSubmit = async (data) => {
    try {
      const { error } = await client.auth.signUp(
        {
          email: data.email,
          password: data.password,
        },
        { redirectTo: process.env.REACT_APP_SITE_URL + "/login" }
      );

      if (error) {
        setError("signUp", { message: error.message });
      } else {
        showNotification(t('signup_successful'), 'success');
        reset();
        navigate('/login');
      }
    } catch (err) {
      setError("signUp", { message: err.message });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        {/* Logo and Title */}
        <Avatar src={logo} alt="logo" sx={{ width: 64, height: 64, mb: 2 }} />


        {/* Sign-Up Card */}
        <Card variant="outlined" sx={{ width: '100%', boxShadow: 2, padding: 3 }}>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('email')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('password')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type={showPassword ? 'text' : 'password'}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('confirmPassword')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type={showConfirmPassword ? 'text' : 'password'}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                disabled={isSubmitting}
                type="submit"
              >
                {t('signup')}
              </Button>
            </form>
          </CardContent>
        </Card>

        {/* Login Prompt */}
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          {t('already_account')}{' '}
          <Button
            sx={{ textTransform: 'none', color: 'primary.main', padding: 0 }}
            onClick={() => navigate('/login')}
          >
            {t('login')}
          </Button>
        </Typography>
      </Box>
    </Container>
  );
};

export default SignUp;
