import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, InputAdornment } from '@mui/material';
import { formatAmount, currencySymbol } from '../../utils';
import CheckIcon from '@mui/icons-material/Check';
import Metrics from './Metrics';
import ClickableTooltip from '../../utils/ClickableTooltip';
import { getForecast, updateForecast, statsForecast } from '../../api';
import Form from 'react-bootstrap/Form';

const Stats = ({ user, real_stats, asset, t }) => {
  const [forecast, setForecast] = useState(null);
  const [forecastStats, setForecastStats] = useState(null);
  const [cashflow, setCashflow] = useState('');
  const [appreciation, setAppreciation] = useState('');
  const [period, setPeriod] = useState('');
  const [showForecastForm, setShowForecastForm] = useState(false);

  const fetchData = async () => {
    try {
      const forecastResp = await getForecast(asset.id);
      setForecast(forecastResp.data);
      setCashflow(forecastResp.data.cashflow);
      setAppreciation(forecastResp.data.appreciation);
      setPeriod(forecastResp.data.period);

      const resp = await statsForecast(forecastResp.data);
      setForecastStats(resp.data);
    } catch (error) {
      console.error('Error fetching forecast data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleForecastForm = () => {
    setShowForecastForm((prev) => !prev);
    if (!showForecastForm) {
      setCashflow(forecast.cashflow);
      setAppreciation(forecast.appreciation);
      setPeriod(forecast.period);
    }
  };

  const handleUpdateForecast = async () => {
    try {
      const updatedForecastData = {
        id: forecast.id,
        asset_id: asset?.id,
        cashflow: parseFloat(cashflow),
        appreciation: parseFloat(appreciation),
        period: parseInt(period),
        price: asset?.purchase_price,
        fees: asset?.acquisition_cost,
        start: asset?.purchase_date
      };

      const resp = await updateForecast(updatedForecastData);
      setForecast(resp.data);

      const response = await statsForecast(resp.data);
      setForecastStats(response.data);
      toggleForecastForm();
    } catch (error) {
      console.error('Error updating forecast:', error);
    }
  };

  return (
    <Box>
      <Box sx={{ flexDirection: 'column', mt: 2, mb: 2, backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
        <ClickableTooltip title={t('tooltip_annualised_metrics')}>
          <Typography variant="h6" component="h6" sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.03)', border: '1px solid #e0e0e0', p: 1 }}>
            {t('metrics_section_title')}
          </Typography>
        </ClickableTooltip>
        <Metrics user={user} stats={real_stats} t={t} />
      </Box>

      {asset?.type !== "FINANCIAL_PRODUCT" &&
        <Box sx={{ flexDirection: 'column', mt: 2, mb: 2, backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
          <ClickableTooltip title={t('tooltip_simu')}>
            <Typography variant="h6" component="h6" sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.03)', border: '1px solid #e0e0e0', p: 1 }}>
              {t('metrics_simulation_title')}
            </Typography>
          </ClickableTooltip>
          <Metrics user={user} stats={forecastStats} t={t} />

          {!asset?.readOnly && (
            <Form.Check
              type="switch"
              label={t('edit_projections')}
              onChange={toggleForecastForm}
              checked={showForecastForm}
              style={{ fontSize: '0.9rem', color: '#6c757d', display: 'flex' }}
            />
          )}
        </Box>
      }

      {showForecastForm && (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 2, backgroundColor: 'rgba(0, 0, 0, 0.03)', p: 2, borderRadius: 1 }}>
          <TextField
            label={t('cashflow')}
            variant="outlined"
            type="number"
            value={cashflow}
            onChange={(e) => setCashflow(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
            }}
            sx={{ mb: 2 }}
          />
          <TextField
            label={t('appreciation')}
            variant="outlined"
            type="number"
            value={appreciation}
            onChange={(e) => setAppreciation(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            sx={{ mb: 2 }}
          />
          <TextField
            label={t('exploitationPeriod')}
            variant="outlined"
            type="number"
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('year')}(s)</InputAdornment>,
            }}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="success" onClick={handleUpdateForecast} startIcon={<CheckIcon />}>
            {t('ok')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Stats;
