import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Card,
  CardContent,
  Button,
  Fab,
  Dialog,
  DialogContent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';
import ReviewRequestForm from './ReviewRequestForm';
import { approveReview, rejectReview } from '../../api';

const ReviewsAdminPanel = ({ onCreateRequest }) => {
  const { t } = useTranslation();
  const { pendingReviews, refreshPendingReviews } = useDataContext();
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    refreshPendingReviews();
  }, []);

  // Handle tab change
  const handleTabChange = (_, newValue) => setActiveTab(newValue);

  // Open and close dialog for new review request
  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);

  // Approve a review and refresh the list
  const handleApprove = async (reviewId) => {
    try {
      await approveReview(reviewId);
      refreshPendingReviews(); // Refresh after approval
    } catch (error) {
      console.error("Failed to approve review:", error);
    }
  };

  // Reject a review and refresh the list
  const handleReject = async (reviewId) => {
    try {
      await rejectReview(reviewId);
      refreshPendingReviews(); // Refresh after rejection
    } catch (error) {
      console.error("Failed to reject review:", error);
    }
  };

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label={t('Concierge Stats')} />
        <Tab label={t('Pending Reviews')} />
      </Tabs>

      {/* Pending Reviews Tab */}
      {activeTab === 1 && (
        <Box sx={{ p: 2 }}>
          {pendingReviews.length ? (
            pendingReviews.map((review) => (
              <Card key={review.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t('Concierge')}: {review.concierge_name}
                  </Typography>
                  <Typography variant="body2">{t('User')}: {review.user_name}</Typography>
                  <Typography variant="body2">{t('Rating')}: {review.rating}</Typography>
                  <Typography variant="body2">{t('Comment')}: {review.comment}</Typography>
                  <Box mt={2}>
                    <Button color="primary" onClick={() => handleApprove(review.id)}>{t('Approve')}</Button>
                    <Button color="error" onClick={() => handleReject(review.id)}>{t('Reject')}</Button>
                  </Box>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>{t('No pending reviews')}</Typography>
          )}
        </Box>
      )}

      {/* Floating Action Button for New Review Request */}
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={handleOpenDialog}
      >
        <AddIcon />
      </Fab>

      {/* New Review Request Dialog */}
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogContent>
          <ReviewRequestForm onCreateRequest={onCreateRequest} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ReviewsAdminPanel;
