import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Chip,
  Divider,
  Button,
  IconButton,
  Collapse,
  CardMedia,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import StarIcon from '@mui/icons-material/Star';
import DeleteIcon from '@mui/icons-material/Delete';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../utils';
import vehicleImage from '../../resources/vehicule.jpeg';

const ConciergeCard = ({ concierge, onRequest, onEdit, onDelete, role }) => {
  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [reviewsOpen, setReviewsOpen] = useState(false);

  const handleToggleDetails = () => setDetailsOpen((prev) => !prev);
  const handleOpenReviews = () => setReviewsOpen(true);
  const handleCloseReviews = () => setReviewsOpen(false);

  return (
    <Card
      sx={{
        borderRadius: 3,
        boxShadow: 3,
        maxWidth: 420,
        mx: 'auto',
        my: 2,
      }}
    >
      {/* Top Section with Compact Avatar */}
      <Box sx={{ bgcolor: 'lightblue', color: 'white', textAlign: 'center', py: 1 }}>
        <CardMedia
          component="img"
          src={concierge.logoUrl || vehicleImage}
          alt={concierge.name}
          sx={{
            width: 80,
            height: 80,
            borderRadius: '50%',
            border: '3px solid white',
            margin: '0 auto',
          }}
        />
        <Typography variant="h6" fontWeight="bold" sx={{ mt: 1 }}>
          {concierge.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
          {concierge.city}, {concierge.country}
        </Typography>
        <Chip label={t(concierge.category)} variant="outlined" sx={{ color: 'black', borderColor: 'black', mb: 1 }} />
      </Box>


<Box sx={{ bgcolor: '#011826', color: 'white', py: 1.5, px: 2 }}>
  <Grid container spacing={2}>

    {/* Combined Average Rating and Review Count */}
    <Grid item xs={6}>
      <Tooltip title={`${t('Average Rating')}: ${concierge.reviews_count ? (concierge.rating_count / concierge.reviews_count).toFixed(1) : 'N/A'}`} arrow>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" alignItems="center" sx={{ mb: 0.5 }}>
            <Typography variant="h6" fontWeight="bold" color="white" sx={{ mr: 0.5 }}>
              {concierge.reviews_count
                ? (concierge.rating_count / concierge.reviews_count).toFixed(1).replace(/\.0$/, '')
                : 'NaN'}
            </Typography>
            <StarIcon sx={{ color: '#FFC107' }} />
          </Box>
          <Button onClick={handleOpenReviews} size="small" sx={{ textTransform: 'none', color: 'white', fontWeight: 'bold' }}>
          {concierge.reviews_count} {t('reviews')}
          </Button>
        </Box>
      </Tooltip>
    </Grid>

    {/* ROI */}
    <Grid item xs={6}>
      <Tooltip title={t('average_roi')} arrow>
        <Box textAlign="center">
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ color: concierge.roi >= 0 ? 'green' : 'red' }}
          >
            {concierge.roi >= 0 ? `+${(concierge.roi * 100).toFixed(2)}%` : `${(concierge.roi * 100).toFixed(2)}%`}
          </Typography>
          <Typography variant="caption" fontWeight="bold" color="white">
            {t('ROI')}
          </Typography>
        </Box>
      </Tooltip>
    </Grid>

 {/* Average Asset Price */}
 <Grid item xs={6}>
      <Tooltip title={t('average_asset_price')} arrow>
        <Box textAlign="center">
          <Typography variant="h6" fontWeight="bold" color="white">
            {formatAmount(concierge.asset_price, concierge.currency, true)}
          </Typography>
          <Typography variant="caption" fontWeight="bold" color="white">
            {t('price')}
          </Typography>
        </Box>
      </Tooltip>
    </Grid>

    {/* Management Fees */}
    <Grid item xs={6}>
      <Tooltip title={t('management_fees')} arrow>
        <Box textAlign="center">
          <Typography variant="h6" fontWeight="bold" color="white">
            {(concierge.fees * 100).toFixed(2)}%
          </Typography>
          <Typography variant="caption" fontWeight="bold" color="white">
            {t('fees')}
          </Typography>
        </Box>
      </Tooltip>
    </Grid>
  </Grid>
</Box>
      {/* Service Details Section */}
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">{t('details')}</Typography>
          <IconButton onClick={handleToggleDetails} size="small">
            {detailsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Collapse in={detailsOpen}>
        {role==="ADMIN" && <Typography>Code:{concierge.code}</Typography>}
          <Typography variant="body2" color="textSecondary">
            {concierge.description}
          </Typography>
        </Collapse>
      </CardContent>
      <Divider sx={{ mt: 0 }} />
      {/* Action Buttons Section */}
      <Box sx={{ display: 'flex', gap: 1, p: 1, justifyContent: 'center' }}>
        {role === 'INDIVIDUAL' && (
          <>
            <Button
              variant="contained"
              size="large"
              color="primary"
              startIcon={<VerifiedIcon />}
              sx={{ borderRadius: 8, textTransform: 'none', fontWeight: 'bold' }}
              onClick={() => onRequest(concierge)}
            >
              {t('hire')}
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              startIcon={<ChatIcon />}
              disabled
              sx={{
                borderRadius: 8,
                textTransform: 'none',
                fontWeight: 'bold',
                borderColor: 'primary.main',
              }}
              onClick={() => onEdit(concierge)}
            >
              {t('chat')}
            </Button>
          </>
        )}
        {role === 'ADMIN' && (
          <>
            <Button
              variant="contained"
              size="large"
              color="primary"
              startIcon={<EditIcon />}
              sx={{ borderRadius: 8, textTransform: 'none', fontWeight: 'bold' }}
              onClick={() => onEdit(concierge)}
            >
              {t('Edit')}
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="error"
              startIcon={<DeleteIcon />}
              sx={{
                borderRadius: 8,
                textTransform: 'none',
                fontWeight: 'bold',
                borderColor: 'error.main',
              }}
              onClick={() => onDelete(concierge.id)}
            >
              {t('Delete')}
            </Button>
          </>
        )}
      </Box>

      {/* Reviews Modal */}
      <Dialog open={reviewsOpen} onClose={handleCloseReviews} fullWidth maxWidth="sm">
        <DialogTitle>{t('All Reviews')}</DialogTitle>
        <DialogContent>
          {(concierge.reviews && concierge.reviews.length > 0) ? (
            concierge.reviews.map((review, index) => (
              <Box key={index} my={2}>
                <Typography variant="body1" fontWeight="bold">
                  {review.author}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {review.content}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              {t('No reviews available')}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReviews} color="primary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ConciergeCard;
