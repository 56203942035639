import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext'; // adjust path as needed
import { DataContext } from '../../context/DataContext';

function Logout() {
  const navigate = useNavigate();
  const { saveUser } = useContext(UserContext);
  const { resetData } = useContext(DataContext);

  useEffect(() => {
    // assuming setUser sets the user state to null or another initial state
    saveUser(null);
    resetData();
    localStorage.removeItem('user'); // if you store token or user info in local storage
    localStorage.removeItem('token');
    // Redirecting user to home or login page after logout
    navigate('/');
  }, [navigate, saveUser]);

  return null;
}

export default Logout;
