import React, { useContext, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Container, Box, Typography, Tabs, Tab, Card, CardContent, CardActions, Button } from '@mui/material';
import { UserContext } from '../../context/UserContext';
import { useNotification } from '../../context/NotificationContext';
import { useTranslation } from 'react-i18next';
import InboxIllustration from '../../resources/empty-inbox.svg';
import { getContracts, approveContract, rejectContract } from '../../api';

export default function InboxAdmin() {
  const { user } = useContext(UserContext);
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState(0);

  // Fetch contracts in "ACCEPTED" status
  const { data: contracts, isLoading } = useQuery(
    ['contracts', user.id],
    () => getContracts(user.id),
    {
      enabled: !!user?.id,
      select: (response) => response.data.filter(contract => contract.status === 'ACCEPTED') || [],
    }
  );

  // Mutations for approving and rejecting contracts
  const approveContractMutation = useMutation((contractId) => approveContract({ request_id: contractId }), {
    onSuccess: () => {
      showNotification(t('contract_approved_successfully'), 'success');
      queryClient.invalidateQueries(['contracts', user.id]);
    },
    onError: (error) => {
      showNotification(t('contract_approval_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    },
  });

  const rejectContractMutation = useMutation((contractId) => rejectContract(contractId), {
    onSuccess: () => {
      showNotification(t('contract_rejected_successfully'), 'success');
      queryClient.invalidateQueries(['contracts', user.id]);
    },
    onError: (error) => {
      showNotification(t('contract_rejection_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    },
  });

  // Handlers for approving and rejecting contracts
  const handleApproveContract = async (contractId) => {
    await approveContractMutation.mutateAsync(contractId);
  };

  const handleRejectContract = async (contractId) => {
    await rejectContractMutation.mutateAsync(contractId);
  };

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  // Check if there are contracts to display
  const hasContracts = contracts && contracts.length > 0;

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" my={3}>
        <Typography variant="h4">{t('inbox')}</Typography>
      </Box>

      {hasContracts ? (
        <>
          {/* Tab Navigation */}
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label={t('accepted_contracts')} />
          </Tabs>

          <Box my={3}>
            {activeTab === 0 && (
              <>
                {isLoading ? (
                  <Typography variant="body2">{t('loading')}</Typography>
                ) : (
                  contracts.map((contract) => (
                    <Card key={contract.id} sx={{ my: 2, boxShadow: 3 }}>
                      <CardContent>
                        <Typography variant="subtitle1"><strong>{t('owner_name')}:</strong> {contract.user_name}</Typography>
                        <Typography variant="subtitle1"><strong>{t('asset_name')}:</strong> {contract.asset_name}</Typography>
                        <Typography variant="subtitle1"><strong>{t('asset_category')}:</strong> {t(contract.asset_category)}</Typography>
                        <Typography variant="subtitle1"><strong>{t('asset_type')}:</strong> {t(contract.asset_type)}</Typography>
                        <Typography variant="subtitle1"><strong>{t('contract_duration')}:</strong> {`${contract.start_date} - ${contract.end_date}`}</Typography>
                        <Typography variant="subtitle1"><strong>{t('message')}:</strong> {contract.message}</Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleApproveContract(contract.id)}
                          sx={{ mr: 1 }}
                        >
                          {t('approve')}
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleRejectContract(contract.id)}
                        >
                          {t('reject')}
                        </Button>
                      </CardActions>
                    </Card>
                  ))
                )}
              </>
            )}
          </Box>
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" my={3}>
          <Box sx={{ width: '60%', maxWidth: '150px', mb: 2 }}>
            <img src={InboxIllustration} alt="No Actions Required" style={{ animation: 'bounce 2s infinite' }} />
          </Box>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {t('no_actions_required')}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            {t('you_are_all_caught_up')}
          </Typography>
        </Box>
      )}
    </Container>
  );
}
