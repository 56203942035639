import Highcharts from 'highcharts';
import React, { useState, useEffect } from 'react';
import { formatAmount } from '../../utils';

const AssetExpenseBreakdownChart = ({ expenses, currency, t }) => {
  const years = Array.from(new Set(Object.keys(expenses).map(date => date.split('-')[1]))).sort();
  const [selectedYear, setSelectedYear] = useState(years[years.length - 1]);

  useEffect(() => {
    const hasExpenses = Object.entries(expenses)
      .filter(([date, _]) => date.includes(selectedYear))
      .some(([_, expense]) => Object.values(expense).some(amount => amount > 0));

    if (!hasExpenses) {
      document.getElementById('expense-breakdown-chart').innerHTML = t('no_expense_in_year') + selectedYear;
      return;
    }

    const filteredExpenses = Object.entries(expenses)
      .filter(([date, _]) => date.includes(selectedYear))
      .reduce((acc, [_, expense]) => {
        Object.entries(expense).forEach(([category, amount]) => {
          acc[category] = (acc[category] || 0) + amount;
        });
        return acc;
      }, {});

    const pieData = Object.entries(filteredExpenses).map(([category, amount]) => ({
      name: t(category),
      y: amount,
      formatted: formatAmount(amount, currency)
    }));

    const chartOptions = {
      chart: {
        type: 'pie',
        backgroundColor: 'transparent',
        options3d: {
          enabled: true,
          alpha: 45
        },
        style: {
          fontFamily: 'Arial'
        }
      },
      title:null,
      tooltip: {
        pointFormatter: function () {
          return `${this.series.name}: <b>${this.formatted}</b>`;
        }
      },
      plotOptions: {
        pie: {
          innerSize: '40%',
          depth: 45,
          dataLabels: {
            enabled: true,
            format: '{point.name}: <b>{point.formatted}</b>'
          },
          showInLegend: true
        }
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        itemMarginTop: 5,
        itemMarginBottom: 5
      },
      series: [{
        name: t('Expenses'),
        colorByPoint: true,
        data: pieData
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 400
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal',
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false
                }
              }
            }
          }
        }]
      }
    };

    Highcharts.chart('expense-breakdown-chart', chartOptions);
  }, [selectedYear, expenses, currency, t]);

  const dropdownStyle = {
    padding: '10px',
    fontSize: '1rem',
    width: '100%',
    marginBottom: '20px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    border: '1px solid #ddd',
    borderRadius: '5px'
  };

  return (
    <div>
      <p></p>
      <select
        onChange={(e) => setSelectedYear(e.target.value)}
        value={selectedYear}
        style={dropdownStyle}
      >
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <div id="expense-breakdown-chart" style={{ height: '500px', minWidth: '310px' }} />
    </div>
  );
};

export default AssetExpenseBreakdownChart;
