import React, { createContext, useState, useContext } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({});

  // Generic function to refresh data by key
  const refreshData = async (key, apiFunc) => {
    try {
      const response = await apiFunc();
      setData(prevData => ({
        ...prevData,
        [key]: response.data,
      }));
    } catch (error) {
      console.error(`Failed to refresh ${key}:`, error);
    }
  };

   // Function to reset all data
   const resetData = () => setData({});

   return (
     <DataContext.Provider value={{ data, refreshData, resetData }}>
       {children}
     </DataContext.Provider>
   );
 };

export const useDataContext = () => useContext(DataContext);
