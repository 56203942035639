import React, { useState, useRef } from 'react';
import AssetTypeSelector from './steps/TypeSelector';
import General from './steps/General';
import DetailsImmo from './steps/DetailsImmo';
import DetailsAuto from './steps/DetailsAuto';
import Location from './steps/Location';
import Forecast from './steps/Forecast'
import Finances from './steps/Finances';
import Summary from './steps/Summary';
import { MobileStepper, Button, Box, Typography } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';

const AssetCreationWizard = ({ user, t, applyAsset, onCancel }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [general, setGeneral] = useState({ type: '' });
  const [details, setDetails] = useState({});
  const [forecast, setForecast] = useState({});
  const [location, setLocation] = useState({});
  const [summary, setSummary] = useState({});
  const theme = useTheme();



  const stepComponents = {
    'REAL_ESTATE': ['type', 'general', 'location', 'characteristics', 'finances', 'summary'],
    'FINANCIAL_PRODUCT': ['type', 'general', 'finances', 'summary'],
    'AUTOMOBILE': ['type', 'general',  'details', 'finances', 'summary'],
    'OTHER_ASSET': ['type', 'general',  'finances', 'summary'],
  };

  const steps = general.type ? stepComponents[general.type] : ['type'];

  const handleForecastUpdate = (newData) => {
    setForecast(newData);
  };
  // Determine if the current step is the last step
  const isLastStep = steps ? activeStep === steps.length - 1 : false;
  const handleSubmit = (formData) => {
    console.log("handleSubmit ", formData);
    const currentStep = steps[activeStep];
    console.log("AssetCreationWizard - handleSubmit", formData, " currentStep: ", currentStep);
    switch (currentStep) {
      case 'type':
      case 'general':
      case 'finances':
          setGeneral(currentData => ({
            ...currentData,
            ...formData
          }));
          break;
      case 'location':
        setLocation(currentData => ({ ...currentData, ...formData }));
        break;
      case 'characteristics':
      case 'details':
        setDetails(currentData => ({ ...currentData, ...formData }));
        break;
      case 'forecast': // Not reached .. last step , a bit complex to use this way so workaround
        setForecast(currentData => ({ ...currentData, ...formData }));
        break;
      case 'summary':
        setSummary(currentData => ({ ...currentData, ...formData }));
        break;
      default:
        console.log("No matching case for the current step");
    }

    // Move to the next step
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };


  const stepRefs = useRef({});

  const handleNext = () => {
    const currentStepKey = steps && steps.length > activeStep ? steps[activeStep] : null;
    const currentStepRef = stepRefs.current[currentStepKey];

    if (currentStepRef && currentStepRef.submitForm) {
      currentStepRef.submitForm();
    } else {
      if (activeStep < steps.length - 1) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      onCancel();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const getStepContent = (stepIndex) => {
    const step = steps[stepIndex];
    if (step === 'type') {
      return <AssetTypeSelector onSelect={typeSelected} />;
    }
    const stepProps = {
      onSelect: handleSubmit,
      onValidSubmit: handleSubmit,
      ref: el => stepRefs.current[step] = el,
      assetIn: general,
      user,
      t,
      memo: step === 'location' || step === 'characteristics' || step === 'details' ? details : undefined
    };

    const stepComponentMap = {
      'type': AssetTypeSelector,
      'general': General,
      'location': Location,
      'characteristics': DetailsImmo,
      'finances': Finances,
      'forecast': Forecast,
      'details': DetailsAuto,
      'summary': Summary
    };

    const StepComponent = stepComponentMap[step];
    return StepComponent ? <StepComponent {...stepProps} /> : <div>Step not defined</div>;
  };

  const handleFinalSubmit = () => {
    // Proceed with the final submission
    const flagDeposit = general["flagNewDeposit"];
    delete general["flagNewDeposit"];
    if (general.exploitation === '') delete general["exploitation"];
    general.acquisition_cost = parseFloat(general.acquisition_cost) || 0
    general.purchase_price = parseFloat(general.purchase_price) || 0
    if(general.value)
      general.value = parseFloat(general.value) || 0
    else general.value = general.purchase_price

    general['user_id'] = user.id;
    console.log("Final submission data: general: ", general, " flagDeposit: ", flagDeposit, " location: ", location, " details: ", details, " forecast: ", forecast);
    applyAsset(general, flagDeposit, details, forecast, location);
  };

  const typeSelected = (selectedType) => {
    setGeneral(current => ({ ...current, type: selectedType }));
    setActiveStep(1); // Move to the next step after type selection
  };

  return (
    <Box sx={{ width: '100%', flexGrow: 1, mt: -1 }}>
     <Typography variant="h6" align="center" sx={{ my: 2 }}>
        {t(steps[activeStep])}
      </Typography>
      {getStepContent(activeStep)}

      {/* Mobile Stepper */}
      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        sx={{
          mt: 1,
          backgroundColor: 'transparent', // Custom background color
          '.MuiMobileStepper-dot': {
            backgroundColor: theme.palette.grey[400], // Custom dot color
          },
          '.MuiMobileStepper-dotActive': {
            backgroundColor: theme.palette.primary.main, // Custom active dot color
          },
        }}
        nextButton={
          isLastStep ? (
            <Button variant="contained" color="primary" onClick={handleFinalSubmit}>{t('submit')}</Button>
          ) : (
            <Button size="small" onClick={handleNext} disabled={activeStep === 0}>
              {t('next')}
              <KeyboardArrowRight />
            </Button>
          )
        }
        backButton={
          <Button size="small" onClick={handleBack}>
            <KeyboardArrowLeft />
            {t('back')}
          </Button>
        }
      />
    </Box>
  );
};

export default AssetCreationWizard;
