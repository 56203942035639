import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import InboxUser from './InboxUser';
import InboxPro from './InboxPro';
import InboxAdmin from './InboxAdmin';
import { Container } from '@mui/material';

export default function Inbox() {
  const { user } = useContext(UserContext);

  // Determine which inbox to show based on user role
  const renderInbox = () => {
    switch (user.role) {
      case 'INDIVIDUAL':
        return <InboxUser />;
      case 'PRO':
        return <InboxPro />;
      case 'ADMIN':
        return <InboxAdmin />;
      default:
        return null;
    }
  };

  return (
    <Container>
      {renderInbox()}
    </Container>
  );
}
