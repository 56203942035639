import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, LinearProgress } from '@mui/material';
import { UserContext } from '../../context/UserContext';
import { useDataContext } from '../../context/DataContext';
import {
  getAssets,
  getConcierges,
  getDebts,
  getStatsHistory,
  getManagedAssets,
  getPendingTransactions,
  getPendingReviews,
  getUserCashflowChart,
  getUserWealthChart,
} from '../../api';

const Loading = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { refreshData } = useDataContext();
  const [loadingProgress, setLoadingProgress] = useState(0);

  // Role-based loading configuration
  const loadingConfig = {
    ADMIN: {
      assets: getAssets,
      concierges: getConcierges },
    PRO: {
      managedAssets: getManagedAssets, },
    INDIVIDUAL: {
      assets: getAssets,
      concierges: getConcierges,
      debts: getDebts,
      statsHistory: getStatsHistory,
      pendingTransactions: getPendingTransactions,
      pendingReviews: getPendingReviews,
      userCashflowChart: getUserCashflowChart,
      userWealthChart: getUserWealthChart
    },
  };

  // Start simulated loading effect with progress increments
  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingProgress(prev => (prev >= 100 ? 100 : prev + 10));
    }, 300);
    return () => clearInterval(interval);
  }, []);

  // Load main assets and navigate to dashboard on completion
  useEffect(() => {
    const loadMainAssets = async () => {
      try {
        const userConfig = loadingConfig[user.role] || {};

        // Fetch data and update DataContext
        await Promise.all(
          Object.entries(userConfig).map(([key, apiFunc]) =>
            refreshData(key, apiFunc)
          )
        );

        // Redirect to dashboard after loading completes
        navigate('/dashboard');
      } catch (error) {
        console.error("Error loading main assets", error);
      }
    };

    loadMainAssets();
  }, [navigate, user.role]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'transparent',
        p: 3,
      }}
    >
      <Box sx={{ position: 'relative', display: 'inline-flex', mb: 3 }}>
        <CircularProgress
          variant="determinate"
          value={loadingProgress}
          size={100}
          thickness={4}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h6" component="div" color="textSecondary">
            {`${loadingProgress}%`}
          </Typography>
        </Box>
      </Box>

      <Typography variant="body1" align="center" sx={{ mb: 2 }}>
        Loading your main data, please wait...
      </Typography>

      <LinearProgress
        variant="determinate"
        value={loadingProgress}
        sx={{
          width: '80%',
          maxWidth: 400,
          borderRadius: 2,
          height: 8,
          backgroundColor: 'grey.300',
          '& .MuiLinearProgress-bar': {
            backgroundColor: 'primary.main',
          },
        }}
      />
    </Box>
  );
};

export default Loading;
