import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

import AssetsUser from './AssetsUser';
import AssetsPro from './AssetsPro';


const Assets = () => {
  const { user } = useContext(UserContext);
  return (
    ['PRO', 'ADMIN'].includes(user.role) ? <AssetsPro user={user} /> : <AssetsUser user={user} />
  );
};

export default Assets;
