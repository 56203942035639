import React, { useContext, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Container, Box, Typography, CardContent, Card, Tabs, CardActions, Tab, Modal, TextField, Button } from '@mui/material';
import PendingTransactionList from '../../utils/PendingTransactionList';
import { UserContext } from '../../context/UserContext';
import { useNotification } from '../../context/NotificationContext';
import { useTranslation } from 'react-i18next';
import InboxIllustration from '../../resources/empty-inbox.svg';
import {
  getContracts,
  getPendingTransactions,
  getChats,
  acceptContract,
  rejectPendingTransaction,
  approvePendingTransaction,
  getAssets,
} from '../../api';


export default function InboxPro() {
  const { user } = useContext(UserContext);
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState(0);
  const [expanded, setExpanded] = useState({});
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [rejectNote, setRejectNote] = useState('');
  const [transactionToReject, setTransactionToReject] = useState(null);

  // Fetch contracts in "SUBMITTED" status
  const { data: contracts } = useQuery(['contracts', user.id], () => getContracts(user.id), {
    enabled: !!user?.id,
    select: (response) => response.data.filter(contract => contract.status === 'SUBMITTED') || [],
  });

  // Fetch transactions that require additional input
  const { data: pendingTransactions } = useQuery(['transactions', user.id], getPendingTransactions, {
    select: (response) => response.data.filter(transaction => transaction.status === 'REQUIRES_INPUT') || [],
  });

  // Fetch assets
  const { data: assets } = useQuery('assets', getAssets, {
    enabled: !!user?.id,
    select: (response) => response.data || [],
  });

  // Placeholder for chats
  const chats = []; // Assuming chats come from an API similar to other data.

  // Check if any data is available for tabs
  const hasContracts = contracts && contracts.length > 0;
  const hasTransactions = pendingTransactions && pendingTransactions.length > 0;
  const hasMessages = chats && chats.length > 0;

  // Contract accept and reject mutations
  const acceptContractMutation = useMutation(
    (contractId) => acceptContract({ request_id: contractId }),
    {
      onSuccess: () => {
        showNotification(t('contract_accepted_successfully'), 'success');
        queryClient.invalidateQueries(['contracts', user.id]);
      },
      onError: (error) => {
        showNotification(t('contract_acceptance_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
      },
    }
  );

  // Approve and reject transaction mutations
  const approveTransactionMutation = useMutation(approvePendingTransaction, {
    onSuccess: () => {
      showNotification(t('transaction_approved'), 'success');
      queryClient.invalidateQueries('pendingTransactions');
    },
    onError: (error) => {
      showNotification(t('transaction_approval_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    },
  });

  const rejectTransactionMutation = useMutation(({ transactionId, note }) => rejectPendingTransaction(transactionId, note), {
    onSuccess: () => {
      showNotification(t('transaction_rejected'), 'success');
      queryClient.invalidateQueries('pendingTransactions');
    },
    onError: (error) => {
      showNotification(t('transaction_rejection_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    },
  });

  // Handle approving and rejecting transactions
  const handleApproveTransaction = async (transactionId) => {
    await approveTransactionMutation.mutateAsync(transactionId);
  };

  const handleRejectTransaction = async () => {
    if (transactionToReject) {
      await rejectTransactionMutation.mutateAsync({ transactionId: transactionToReject, note: rejectNote });
      setRejectNote('');
      setRejectModalOpen(false);
    }
  };

  const openRejectModal = (transactionId) => {
    setTransactionToReject(transactionId);
    setRejectModalOpen(true);
  };

  const closeRejectModal = () => {
    setTransactionToReject(null);
    setRejectNote('');
    setRejectModalOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleExpandClick = (name) => {
    setExpanded((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" my={3}>
        <Typography variant="h4">{t('inbox')}</Typography>
      </Box>

      {(hasContracts || hasTransactions || hasMessages) ? (
        <>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            {hasContracts && <Tab label={t('submitted_contracts')} />}
            {hasTransactions && <Tab label={t('transactions_requiring_input')} />}
            {hasMessages && <Tab label={t('text_messages')} />}
          </Tabs>

          <Box my={3}>
            {/* Contracts Tab */}
            {activeTab === 0 && hasContracts && (
              <Box>
                {contracts.map((contract) => (
                  <Card key={contract.id} sx={{ my: 2, boxShadow: 3 }}>
                    <CardContent>
                      <Typography variant="subtitle1"><strong>{t('owner_name')}:</strong> {contract.user_name}</Typography>
                      <Typography variant="subtitle1"><strong>{t('asset_name')}:</strong> {contract.asset_name}</Typography>
                      <Typography variant="subtitle1"><strong>{t('contract_duration')}:</strong> {`${contract.start_date} - ${contract.end_date}`}</Typography>
                      <Typography variant="subtitle1"><strong>{t('message')}:</strong> {contract.message}</Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => acceptContractMutation.mutate(contract.id)}
                        sx={{ mr: 1 }}
                      >
                        {t('accept')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => rejectTransactionMutation.mutate(contract.id)}
                      >
                        {t('refuse')}
                      </Button>
                    </CardActions>
                  </Card>
                ))}
              </Box>
            )}

            {/* Transactions Tab */}
            {activeTab === 1 && hasTransactions && (
              <PendingTransactionList
                user={user}
                groupedTransactions={{ [user.name]: pendingTransactions }}
                expanded={expanded}
                handleExpandClick={handleExpandClick}
                formatDate={(date) => new Date(date).toLocaleDateString()}
                statusLabels={{ REQUIRES_INPUT: t('requires_input') }}
                statusColors={{ REQUIRES_INPUT: 'warning' }}
                assets={assets}
                partners={[]}
                handleApproveTransaction={handleApproveTransaction}
                handleOpenRejectModal={openRejectModal}
              />
            )}

            {/* Messages Tab */}
            {activeTab === 2 && hasMessages && (
              <Box>
                <Typography variant="h6" sx={{ mb: 2 }}>{t('text_messages')}</Typography>
                {chats.map((message) => (
                  <Card key={message.id} sx={{ my: 2, boxShadow: 3 }}>
                    <CardContent>
                      <Typography variant="subtitle1"><strong>{t('from')}:</strong> {message.sender_name}</Typography>
                      <Typography variant="subtitle1"><strong>{t('date')}:</strong> {message.date}</Typography>
                      <Typography variant="body2">{message.content}</Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" my={3}>
          <Box sx={{ width: '60%', maxWidth: '150px', mb: 2 }}>
            <img src={InboxIllustration} alt="No Actions Required" style={{ animation: 'bounce 2s infinite' }} />
          </Box>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {t('no_actions_required')}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            {t('you_are_all_caught_up')}
          </Typography>
        </Box>
      )}

      {/* Reject Modal */}
      <Modal
        open={rejectModalOpen}
        onClose={closeRejectModal}
        aria-labelledby="reject-transaction-title"
        aria-describedby="reject-transaction-description"
      >
        <Box sx={{ ...modalStyle }}>
          <Typography id="reject-transaction-title" variant="h6">{t('reject_transaction')}</Typography>
          <TextField
            label={t('reason_for_rejection')}
            fullWidth
            multiline
            rows={4}
            value={rejectNote}
            onChange={(e) => setRejectNote(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={handleRejectTransaction} sx={{ mr: 1 }}>
              {t('confirm')}
            </Button>
            <Button variant="outlined" onClick={closeRejectModal}>
              {t('cancel')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
}

// Define a modal style for the rejection note modal
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
