import React, { useState, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { DataContext } from '../../context/DataContext';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../utils';
import {
  Container, Grid, Box, Tabs, Tab, Card, CardContent, Typography, Avatar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '12px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[6],
  },
}));

const DashboardPro = () => {

  return (
    <Container>
    </Container>
  );
};

export default DashboardPro;
