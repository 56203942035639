import ApartmentIcon from '@mui/icons-material/Apartment';
import HouseIcon from '@mui/icons-material/House';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import BusinessIcon from '@mui/icons-material/Business';
import TerrainIcon from '@mui/icons-material/Terrain';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SavingsIcon from '@mui/icons-material/Savings';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import buildings from '../resources/buildings.jpg';
import collectibles from '../resources/collectibles.jpeg';
import house from '../resources/house.jpeg';
import apartment from '../resources/apartment.jpeg';
import equipment from '../resources/equipment.jpeg';
import financial_product from '../resources/financial_product.jpeg';
import land from '../resources/land.jpeg';
import other from '../resources/other.jpeg';
import vehicule from '../resources/vehicule.jpeg';

// Color list for fallback icons
import { purple, green, blue, yellow, indigo, orange, cyan, lime, teal, pink } from '@mui/material/colors';
const colorList = [blue[500], green[500], yellow[600], purple[500], orange[500], pink[500], indigo[500], teal[500], cyan[500], lime[500]];
export const getRandomColor = () => colorList[Math.floor(Math.random() * colorList.length)];

// Icon mapping for asset categories
export const getCategoryIcon = (category) => {
  const iconMap = {
    'HOUSE': <HouseIcon />,
    'APARTMENT': <ApartmentIcon />,
    'BEDROOM': <HotelIcon />,
    'OFFICE': <BusinessIcon />,
    'PARKING': <LocalParkingIcon />,
    'ETF': <ShowChartIcon />,
    'STOCK': <ShowChartIcon />,
    'BOND': <AccountBalanceIcon />,
    'CRYPTOCURRENCY': <CurrencyBitcoinIcon />,
    'SAVINGS_ACCOUNT': <SavingsIcon />,
    'CAR': <DirectionsCarIcon />,
    'LAND': <TerrainIcon />,
    // Add more cases as needed
  };
  return iconMap[category] || <LocalAtmIcon />;
};

// Function to preload images and save them in localStorage
const convertToBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = reject;
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};

const imageSources = {
  'REAL_ESTATE': buildings,
  'FINANCIAL_PRODUCT': financial_product,
  'EQUIPMENT': equipment,
  'AUTOMOBILE': vehicule,
  'COLLECTIBLES': collectibles,
  'HOUSE': house,
  'APARTMENT': apartment,
  'LAND': land,
  'OTHER_ASSET': other,
};

export const preloadImages = async () => {
  const promises = Object.keys(imageSources).map(type =>
    convertToBase64(imageSources[type])
      .then(base64String => localStorage.setItem(`image_${type}`, base64String))
      .catch(error => console.error(`Failed to load ${type} image:`, error))
  );
  await Promise.all(promises);
};
