import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Rating,
  TextField,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { submitReview, deleteReview } from '../../api';
import { useNotification } from '../../context/NotificationContext';

const PendingReviewList = ({ reviews, refresh }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();

  const [selectedReviewId, setSelectedReviewId] = useState(null);
  const [reviewRating, setReviewRating] = useState(0);
  const [reviewComment, setReviewComment] = useState('');

  const submitReviewMutation = useMutation(submitReview, {
    onSuccess: () => {
      showNotification(t('review_submitted'), 'success');
      queryClient.invalidateQueries('pendingReviews');
      setSelectedReviewId(null);
      setReviewRating(0);
      setReviewComment('');
      refresh();
    },
    onError: (error) => {
      showNotification(t('review_submission_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    },
  });

  const deleteReviewMutation = useMutation(deleteReview, {
    onSuccess: () => {
      showNotification(t('review_deleted'), 'success');
      queryClient.invalidateQueries('pendingReviews');
      refresh();
    },
    onError: (error) => {
      showNotification(t('review_deletion_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    },
  });

  const handleSubmitReview = (review) => {
    const reviewData = {
      rating: reviewRating,
      comment: reviewComment,
    };
    submitReviewMutation.mutate({
      review_id: review.id,
      review: reviewData,
    });
  };

  const handleRefuseReview = (reviewId) => {
    deleteReviewMutation.mutate(reviewId);
  };

  const handleOpenReviewInput = (reviewId) => {
    setSelectedReviewId(reviewId);
    setReviewRating(0);
    setReviewComment('');
  };

  if (!reviews.length) return null;

  return (
    <Box my={3}>
      {reviews.map((review) => (
        <Card key={review.id} sx={{ my: 2 }}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography variant="subtitle1">
                  <strong>{t('review_for')}:</strong> {review.concierge_name}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t('asset')}:</strong> {review.asset_name} | {t(review.asset_category)}
                </Typography>
              </Box>
            </Box>
            {selectedReviewId === review.id ? (
              <>
                <Box sx={{ mt: 2 }}>
                  <Typography>{t('provide_rating')}</Typography>
                  <Rating
                    name={`rating-${review.id}`}
                    value={reviewRating}
                    onChange={(event, newValue) => setReviewRating(newValue)}
                    size="large"
                  />
                </Box>
                <TextField
                  label={t('comment')}
                  placeholder={t('Share your feedback...')}
                  multiline
                  rows={3}
                  value={reviewComment}
                  onChange={(e) => setReviewComment(e.target.value)}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </>
            ) : (
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>

              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              {selectedReviewId === review.id ? (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => setSelectedReviewId(null)}
                    fullWidth
                    sx={{ mr: 1 }}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmitReview(review)}
                    fullWidth
                  >
                    {t('submit')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenReviewInput(review.id)}
                    fullWidth
                    sx={{ mr: 1 }}
                  >
                    {t('accept')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleRefuseReview(review.id)}
                    fullWidth
                  >
                    {t('refuse')}
                  </Button>
                </>
              )}
            </Box>
          </CardActions>
        </Card>
      ))}
    </Box>
  );
};

export default PendingReviewList;
