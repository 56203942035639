import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Paper,
  IconButton,
  Collapse,
  InputAdornment,
  MobileStepper,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { currencySymbol } from '../../utils';
import i18next from 'i18next';
import { configExpenseTypes, configIncomeTypes } from '../../api';
import { useTheme } from '@mui/material/styles';
import { useNotification } from '../../context/NotificationContext';

const TransactionManager = ({ user, apply, assets, owners, initialData, partners = [] }) => {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState(initialData ? initialData.transactions : [{ title: '', category: '', amount: '', tags: '', partner_id: null, occupancy: null, notes: null, type: 'INCOME' }]);
  const [owner, setOwner] = useState(initialData ? initialData.owner_id : '');
  const [asset, setAsset] = useState(initialData ? initialData.asset_id : '');
  const [transactionDate, setTransactionDate] = useState(initialData ? initialData.date : '');
  const [activeStep, setActiveStep] = useState(0);
  const [incomeTypes, setIncomeTypes] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const theme = useTheme();
  const { showNotification } = useNotification();

  const [assetPurchaseDate, setAssetPurchaseDate] = useState(''); // State to hold the selected asset's purchase date

  const handleAssetChange = (assetId) => {
    setAsset(assetId);
    // Find the selected asset's purchase date and set it as the minimum date
    const selectedAsset = assets.find((asset) => asset.id === assetId);
    setAssetPurchaseDate(selectedAsset ? selectedAsset.purchase_date : '');
  };

  useEffect(() => {
    configIncomeTypes().then(res => setIncomeTypes(res.data));
    configExpenseTypes().then(res => setExpenseTypes(res.data));
  }, []);
// In TransactionManager component

  useEffect(() => {
    if (initialData) {
      setOwner(initialData.owner_id);
      setAsset(initialData.asset_id);
      setTransactionDate(initialData.date);
      setTransactions(initialData.transactions.map(transaction => ({
        ...transaction,
        category: transaction.category || getCurrentTypes(transaction.type)[0], // Set default category if not already set
      })));
    }
  }, [initialData]);

  const steps = [t('general'), t('details'), t('confirm')];
  const formatDate = (dateString) => new Date(dateString).toLocaleDateString(i18next.language);

  const handleAddTransaction = () => {
    setTransactions([...transactions, { title: '', category: getCurrentTypes('INCOME')[0], amount: '', tags: '', partner_id: null, occupancy: null, notes: null, type: 'INCOME' }]); // Set default category
  };

  const handleTransactionTypeChange = (index, type) => {
    const newTransactions = transactions.map((transaction, i) => (
      i === index ? { ...transaction, type, category: getCurrentTypes(type)[0] } : transaction // Set default category based on type
    ));
    setTransactions(newTransactions);
  };

  const handleRemoveTransaction = (index) => {
    const newTransactions = transactions.slice();
    newTransactions.splice(index, 1);
    setTransactions(newTransactions);
  };

  const handleNext = () => {
    if (activeStep === 0 && (!owner || !asset || !transactionDate)) {
      showNotification(t('please_fill_all_fields'), 'info');
      return;
    }
    if (activeStep === 1 && transactions.some(transaction => !transaction.title || !transaction.category || !transaction.amount)) {
      showNotification(t('please_fill_all_fields'), 'info');
      return;
    }
    if (activeStep === 1 && transactions.length === 0) {
      showNotification(t('please_fill_all_fields'), 'info');
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleTransactionChange = (index, field, value) => {
    const newTransactions = [...transactions];
    newTransactions[index][field] = value;
    setTransactions(newTransactions);
  };

  const getCurrentTypes = (type) => {
    return type === 'INCOME' ? incomeTypes : expenseTypes;
  };

  const handleFormSubmit = () => {
    const updatedTransactions = transactions.map(transaction => ({
      ...transaction,
      tags: Array.isArray(transaction.tags) ? transaction.tags : (transaction.tags ? transaction.tags.split(',').map(tag => tag.trim()) : [])
    }));
    apply({
      id: initialData ? initialData.id : null,
      user_id: user.id,
      owner_id: owner,
      asset_id: asset,
      date: transactionDate,
      details: updatedTransactions,
    });
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>{t('owner')}</InputLabel>
                  <Select
                value={owner}
                onChange={(e) => {
                  setOwner(e.target.value);
                  setAsset(''); // Reset asset selection when owner changes
                  setAssetPurchaseDate(''); // Reset the purchase date when the owner changes
                }}
                label={t('owner')}
                disabled={!!initialData} // Disable if initialData exists (edit mode)
              >
                {owners.map((owner) => (
                  <MenuItem key={owner.id} value={owner.id}>
                    {`${owner.name} ${owner.surname}`}
                  </MenuItem>
                ))}
              </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('asset')}</InputLabel>
              <Select
                value={asset}
                onChange={(e) => handleAssetChange(e.target.value)} // Use the new handler function
                label={t('asset')}
                disabled={!owner || !!initialData} // Disable if no owner is selected or in edit mode
              >
                {assets.filter((asset) => asset.user_id === owner).map((asset) => (
                  <MenuItem key={asset.id} value={asset.id}>
                    {asset.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="date"
              label={t('transaction_date')}
              InputLabelProps={{ shrink: true }}
              value={transactionDate}
              onChange={(e) => setTransactionDate(e.target.value)}
              error={!transactionDate}
              inputProps={{
                min: assetPurchaseDate, // Dynamically set the minimum date based on asset selection
              }}
            />
          </Grid>
            </Grid>
          </Box>
        );
      case 1:
        return (
          <form>
            {transactions.map((transaction, index) => (
              <Paper key={index} sx={{ p: 1, mb: 2, backgroundColor: theme.palette.grey[100] }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        variant={transaction.type === 'INCOME' ? 'contained' : 'outlined'}
                        color="primary"
                        onClick={() => handleTransactionTypeChange(index, 'INCOME')}
                        sx={{ mr: 1 }}
                      >
                        {t('income')}
                      </Button>
                      <Button
                        variant={transaction.type === 'EXPENSE' ? 'contained' : 'outlined'}
                        color="secondary"
                        onClick={() => handleTransactionTypeChange(index, 'EXPENSE')}
                      >
                        {t('expense')}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} sx={{ mt: 1 }}>
                    <TextField
                      label={t('title')}
                      fullWidth
                      value={transaction.title}
                      onChange={(e) => handleTransactionChange(index, 'title', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} sx={{ mt: -1 }}>
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel>{t('category')}</InputLabel>
                      <Select
                        value={transaction.category}
                        onChange={(e) => handleTransactionChange(index, 'category', e.target.value)}
                        label={t('category')}
                      >
                        {getCurrentTypes(transaction.type).map((type) => (
                          <MenuItem key={type} value={type}>
                            {t(type)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      type="number"
                      label={t('amount')}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
                      }}
                      fullWidth
                      value={transaction.amount}
                      onChange={(e) => handleTransactionChange(index, 'amount', e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', my: 0.5 }}>
        <Button
          size="small"
          startIcon={showOptionalFields ? <RemoveIcon /> : <AddIcon />}
          onClick={() => setShowOptionalFields(prev => !prev)}
        >
          {showOptionalFields ? t('Less Details') : t('More Details')}
        </Button>
      </Box>


                  </Grid>
                  <Collapse in={showOptionalFields} timeout="auto" unmountOnExit>
                  <Grid item xs={12} sm={6} md={3} sx={{ mb: 1, ml: 1 }}>
                    <TextField
                      label={t('tags')}
                      fullWidth
                      placeholder={t('enter_tags_comma_separated')}
                      value={transaction.tags}
                      onChange={(e) => handleTransactionChange(index, 'tags', e.target.value)}
                    />
                  </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{ mb: 1, ml: 1 }}>
                      <FormControl fullWidth variant="outlined" disabled>
                        <InputLabel>{t('partner')}</InputLabel>
                        <Select
                          value={transaction.partner_id}
                          onChange={(e) => handleTransactionChange(index, 'partner_id', e.target.value)}
                          label={t('partner')}
                        >
                          <MenuItem value="">{t('no_partner')}</MenuItem>
                          {partners.map((partner) => (
                            <MenuItem key={partner.id} value={partner.id}>
                              {partner.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {transaction.type === "INCOME" && <Grid item xs={12} sm={6} md={3} sx={{ mb: 1, ml: 1 }}>
                      <TextField
                        label={t('occupancy')}
                        type="number"
                        fullWidth
                        value={transaction.occupancy}
                        onChange={(e) => handleTransactionChange(index, 'occupancy', e.target.value)}
                      />
                    </Grid>}
                    <Grid item xs={12} sx={{ ml: 1 }}>
                      <TextField
                        label={t('notes')}
                        fullWidth
                        multiline
                        rows={2}
                        value={transaction.notes}
                        onChange={(e) => handleTransactionChange(index, 'notes', e.target.value)}
                      />
                    </Grid>
                  </Collapse>

                  <IconButton color="error" onClick={() => handleRemoveTransaction(index)}>
                    <DeleteIcon />
                  </IconButton>

                </Grid>
              </Paper>
            ))}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button size="small" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleAddTransaction}>
                {t('add_other_transaction')}
              </Button>
            </Box>
          </form>
        );
      case 2:
        return (
          <Box>
            <Card>
              <CardContent>
                <Typography variant="subtitle1"><strong>{t('asset')}:</strong> {assets.find(a => a.id === asset)?.name}</Typography>
                <Typography variant="subtitle1"><strong>{t('date')}:</strong> {formatDate(transactionDate)}</Typography>
                {transactions.map((transaction, index) => (
                  <Box key={index} sx={{ mt: 2 }}>
                    <Typography variant="subtitle1"><strong>{t('type')}:</strong> {t(transaction.type)}</Typography>
                    <Typography variant="subtitle1"><strong>{t('title')}:</strong> {transaction.title}</Typography>
                    <Typography variant="subtitle1"><strong>{t('category')}:</strong> {t(transaction.category)}</Typography>
                    <Typography variant="subtitle1"><strong>{t('amount')}:</strong> {transaction.amount} {currencySymbol(user.currency)}</Typography>
                    {transaction.tags && <Typography variant="subtitle1"><strong>{t('tags')}:</strong> {transaction.tags}</Typography>}
                    {transaction.partner_id && <Typography variant="subtitle1"><strong>{t('partner')}:</strong> {partners.find(p => p.id === transaction.partner_id)?.name}</Typography>}
                    {transaction.occupancy && <Typography variant="subtitle1"><strong>{t('occupancy')}:</strong> {transaction.occupancy}</Typography>}
                    {transaction.notes && <Typography variant="subtitle1"><strong>{t('notes')}:</strong> {transaction.notes}</Typography>}
                  </Box>
                ))}
              </CardContent>
              <CardActions sx={{ display: 'flex', justifyContent: 'right' }}>
                <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                  {t('submit')}
                </Button>
              </CardActions>
            </Card>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ width: '100%', flexGrow: 1, mt: -1 }}>
      <Typography variant="h6" align="center" sx={{ my: 1 }}>
        {t(steps[activeStep])}
      </Typography>
      {getStepContent(activeStep)}

      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        sx={{
          mt: 1,
          backgroundColor: 'transparent',
          '.MuiMobileStepper-dot': {
            backgroundColor: theme.palette.grey[400],
          },
          '.MuiMobileStepper-dotActive': {
            backgroundColor: theme.palette.primary.main,
          },
        }}
        nextButton={
          activeStep < steps.length - 1 ? (
            <Button size="small" onClick={handleNext}>
              {t('next')}
              <ArrowForwardIcon />
            </Button>
          ) : null
        }
        backButton={
          activeStep !== 0 ? (
            <Button size="small" onClick={handleBack}>
              <ArrowBackIcon />
              {t('back')}
            </Button>
          ) : null
        }
      />
    </Box>
  );
};

export default TransactionManager;
