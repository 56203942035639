import React, { useState, useContext, useCallback } from 'react';
import { useQuery } from 'react-query';
import {
  Box, CircularProgress, Container, Fab, Grid, Button, Fade, Tab, Tabs, Typography, Modal
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getEstimations, deleteEstimation, createEstimation, getImmo, getAuto, getLocation } from '../../api';
import EstimationCreationWizard from './EstimationCreationWizard';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import EstimationCard from '../../utils/EstimationCard';
import { useNotification } from '../../context/NotificationContext';

const Estimations = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const { showNotification } = useNotification();

  const fetchEstimations = useCallback(async () => {
    let response = await getEstimations(user.id);
    const enhancedEstimations = await Promise.all(
      response.data.map(async (estimation) => {
        let details = null;
        let location = null;

        try {
          if (estimation.real_estate_id) {
            const immoResponse = await getImmo(estimation.real_estate_id);
            details = immoResponse.data;
            if (details?.location_id) {
              const locationResponse = await getLocation(details.location_id);
              location = locationResponse.data;
            }
          } else if (estimation.automobile_id) {
            const autoResponse = await getAuto(estimation.automobile_id);
            details = autoResponse.data;
          }
        } catch (error) {
          console.error("Error fetching additional info", error);
        }

        return { ...estimation, details, location };
      })
    );
    return groupByCategory(enhancedEstimations);
  }, [user.id]);

  const { data: estimations, isLoading, refetch } = useQuery(
    ['estimations', user.id],
    fetchEstimations
  );

  const groupByCategory = (estimations) => {
    return estimations.reduce((acc, estimation) => {
      const category = estimation.details?.category || 'Other';
      acc[category] = acc[category] || [];
      acc[category].push(estimation);
      return acc;
    }, {});
  };

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const handleDeleteEstimation = useCallback(async (estimationId) => {
    if (!estimationId) return;
    await deleteEstimation(estimationId);
    refetch();
  }, [refetch]);

  const handleOpenWizard = () => setIsWizardOpen(true);

  const handleCloseWizard = () => setIsWizardOpen(false);

  const handleWizardSubmitEstimation = async (details, location = null) => {
    try {
      const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
      details = isEmptyObject(details) ? null : details;
      location = isEmptyObject(location) ? null : location;
      const data = { details, location };
      await createEstimation(data);
      refetch();
      setIsWizardOpen(false);
      showNotification(t('estimation_creation_successful'));
    } catch (error) {
      console.error('An error occurred while creating Estimation:', error);
      showNotification(t('estimation_creation_failed') + ': ' + error.response?.data?.detail || '');
    }
  };

  return (
    <Container maxWidth="md">
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {estimations && Object.keys(estimations).length === 0 ? (
            <Box textAlign="center" mt={5}>
              <Typography variant="subtitle1" color="text.secondary">
                {t('no_valuation_prompt')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenWizard}
                sx={{ mt: 3 }}
              >
                {t('add_your_first_valuation')}
              </Button>
            </Box>
          ) : (
            <>
              <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" style={{ marginBottom: '10px' }}>
                {estimations && Object.keys(estimations).map((category, index) => (
                  <Tab label={t(category)} key={category} />
                ))}
              </Tabs>

              {estimations && Object.keys(estimations).map((category, index) => (
                <TabPanel value={activeTab} index={index} key={category}>
                  <Grid container spacing={2}>
                    {estimations[category].map((estimation) => (
                      <Grid item key={estimation.id}>
                        <EstimationCard
                          estimation={estimation}
                          location={estimation.location}
                          details={estimation.details}
                          currency={user.currency}
                          t={t}
                          handleDeleteEstimation={handleDeleteEstimation}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </TabPanel>
              ))}

              <Fab
                color="primary"
                aria-label={t('add_estimation')}
                onClick={handleOpenWizard}
                sx={{
                  position: 'fixed',
                  bottom: 16,
                  right: 16,
                  width: 64,
                  height: 64,
                  fontSize: 32,
                }}
              >
                <AddIcon />
              </Fab>
              {isWizardOpen && (
                <EstimationCreationWizard
                  applyEstimation={handleWizardSubmitEstimation}
                  onCancel={handleCloseWizard}
                  t={t}
                  user={user}
                />
              )}
            </>
          )}
        </>
      )}
      <Modal
        open={isWizardOpen}
        onClose={handleCloseWizard}
        closeAfterTransition
        BackdropProps={{
          timeout: 1500,
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={isWizardOpen}>
          <Box sx={{
            background: 'linear-gradient(rgb(255, 255, 255), rgba(162, 225, 238, 0.988))',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxWidth: '500px',
            maxHeight: '90vh',
            overflow: 'auto'
          }}>
            <EstimationCreationWizard
              applyEstimation={handleWizardSubmitEstimation}
              onCancel={handleCloseWizard}
              t={t}
              user={user}
            />
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default Estimations;
