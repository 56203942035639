import React, { useContext, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Container,
  Fab,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Card,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { UserContext } from '../../context/UserContext';
import { getContracts, createContract, approveContract, deleteContract, getAssets, getConcierges } from '../../api';
import ContractForm from './ContractForm';
import ConfirmDialog from '../../utils/ConfirmDialog';
import LoadingModal from '../../utils/LoadingModal';
import { useNotification } from '../../context/NotificationContext';

export default function Contracts() {
  const { user, refreshUser } = useContext(UserContext);
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [basicModal, setBasicModal] = useState(false);
  const [expanded, setExpanded] = useState({});
  const toggleShow = () => setBasicModal(!basicModal);
  const [isLoading, setIsLoading] = useState(false);
  const [contractIdToDelete, setContractIdToDelete] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const { data: contracts, isLoading: contractsLoading } = useQuery(
    'contracts',
    () => getContracts(user.id),
    {
      enabled: !!user?.id,
      select: (response) => response.data || [],
    }
  );

  const { data: assets, isLoading: assetsLoading } = useQuery(
    'assets',
    () => getAssets(),
    {
      enabled: !!user?.id && user.role !== 'PRO',
      select: (response) => response.data || [],
    }
  );

  const { data: concierges, isLoading: conciergesLoading } = useQuery(
    'concierges',
    () => getConcierges(user.id),
    {
      enabled: !!user?.id && user.role !== 'PRO',
      select: (response) => response.data || [],
    }
  );

  const createContractMutation = useMutation(
    ({ asset_id, contract }) => createContract(asset_id, contract),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contracts');
        refreshUser();
      },
    }
  );

  const approveContractMutation = useMutation(
    ({ request }) => approveContract(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contracts');
        refreshUser();
      },
    }
  );


  const deleteContractMutation = useMutation(
    ({ contract_id }) => deleteContract(contract_id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contracts');
        refreshUser();
      },
    }
  );

  const handleNewContract = async (formData) => {
    try {
      setIsLoading(true);
      const { asset_id, concierge_id, ...rest } = formData;
      await createContractMutation.mutateAsync({ asset_id, contract: formData });
      showNotification(t('contract_created_successfully'), 'success');
      toggleShow();
    } catch (error) {
      console.error('An error occurred while creating the contract:', error);
      showNotification(t('contract_creation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleApproveContract = async (contractId) => {
    try {
      setIsLoading(true);
      await approveContractMutation.mutateAsync({ request: { request_id: contractId, user_id: user.id  } });
      showNotification(t('contract_created_successfully'), 'success');
    } catch (error) {
      console.error('An error occurred while approving the contract:', error);
      showNotification(t('contract_approval_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');

    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteContract = async (contractId, status) => {
    if (status === 'ACTIVE') {
      showNotification(t('contract_active_cannot_delete'), 'info');
      return;
    }

    try {
      setIsLoading(true);
      await deleteContractMutation.mutateAsync({ contract_id: contractId });
      showNotification(t('contract_deleted_successfully'), 'success');
    } catch (error) {
      console.error('An error occurred while deleting the contract:', error);
      showNotification(t('contract_deletion_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');

    } finally {
      setIsLoading(false);
    }
  };

  if (contractsLoading || (user.role !== 'PRO' && (assetsLoading || conciergesLoading)) || isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const formatDate = (dateString) => new Date(dateString).toLocaleDateString(i18next.language);

  const activeContracts = (contracts || []).filter(contract => contract.status === 'ACTIVE');
  const nonValidatedContracts = (contracts || []).filter(contract => contract.status === 'REJECTED' || contract.status === 'REFUSED');

  const handleExpandClick = (section) => {
    setExpanded((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" my={3}>
        <Typography variant="h4">{t('contracts')}</Typography>
      </Box>

      {contracts.length === 0 ? (
        <Box textAlign="center" m={3}>
          <Typography variant="subtitle1" color="text.secondary">
            {t('no_contracts_prompt')}
          </Typography>
          {user.role !== 'PRO' && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={toggleShow}
              sx={{ mt: 2 }}
            >
              {t('add_contract')}
            </Button>
          )}
        </Box>
      ) : (
        <>
          <Box my={3}>
            <Typography variant="h6" sx={{ mb: 2 }} onClick={() => handleExpandClick('activeContracts')}>
              {t('validated_contracts')}
              <IconButton size="small">
                {expanded.activeContracts ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Typography>
            <Collapse in={expanded.activeContracts} timeout="auto" unmountOnExit>
              {activeContracts.length > 0 ? (
                activeContracts.map((contract) => (
                  <Card key={contract.id} sx={{ my: 2 }}>
                    <CardContent>
                      <Typography variant="subtitle1"><strong>{t('owner_name')}:</strong> {contract.user_name}</Typography>
                      <Typography variant="subtitle1"><strong>{t('asset_name')}:</strong> {contract.asset_name}</Typography>
                      <Typography variant="subtitle1"><strong>{t('asset_category')}:</strong> {t(contract.asset_category)}</Typography>
                      <Typography variant="subtitle1"><strong>{t('asset_type')}:</strong> {t(contract.asset_type)}</Typography>
                      <Typography variant="subtitle1"><strong>{t('concierge')}:</strong> {contract.concierge_name}</Typography>
                      <Typography variant="subtitle1"><strong>{t('contract_duration')}:</strong> {`${formatDate(contract.created_at)} - ${formatDate(contract.expiry_date)}`}</Typography>
                      <Typography variant="subtitle1"><strong>{t('message')}:</strong> {contract.message}</Typography>
                      <Typography variant="subtitle1"><strong>{t('status')}:</strong> {t(contract.status)}</Typography>
                    </CardContent>
                  </Card>
                ))
              ) : (
                <Typography variant="body2">{t('no_contract')}</Typography>
              )}
            </Collapse>
          </Box>

          <Box my={3}>
            <Typography variant="h6" sx={{ mb: 2 }} onClick={() => handleExpandClick('nonValidatedContracts')}>
              {t('non_validated_contracts')}
              <IconButton size="small">
                {expanded.nonValidatedContracts ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Typography>
            <Collapse in={expanded.nonValidatedContracts} timeout="auto" unmountOnExit>
              {nonValidatedContracts.length > 0 ? (
                nonValidatedContracts.map((contract) => (
                  <Card key={contract.id} sx={{ my: 2 }}>
                    <CardContent>
                      <Typography variant="subtitle1"><strong>{t('owner_name')}:</strong> {contract.user_name}</Typography>
                      <Typography variant="subtitle1"><strong>{t('asset_name')}:</strong> {contract.asset_name}</Typography>
                      <Typography variant="subtitle1"><strong>{t('asset_category')}:</strong> {t(contract.asset_category)}</Typography>
                      <Typography variant="subtitle1"><strong>{t('asset_type')}:</strong> {t(contract.asset_type)}</Typography>
                      <Typography variant="subtitle1"><strong>{t('concierge')}:</strong> {contract.concierge_name}</Typography>
                      <Typography variant="subtitle1"><strong>{t('contract_duration')}:</strong> {`${formatDate(contract.created_at)} - ${formatDate(contract.expiry_date)}`}</Typography>
                      <Typography variant="subtitle1"><strong>{t('message')}:</strong> {contract.message}</Typography>
                      <Typography variant="subtitle1"><strong>{t('status')}:</strong> {t(contract.status)}</Typography>
                    </CardContent>
                    <CardActions>
                      {user.role === 'INDIVIDUAL' && (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDeleteContract(contract.id, contract.status)}
                          sx={{ mr: 1 }}
                        >
                          {t('delete')}
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                ))
              ) : (
                <Typography variant="body2">{t('no_contract')}</Typography>
              )}
            </Collapse>
          </Box>

          {user.role !== 'PRO' && (
            <Fab
              color="primary"
              aria-label={t('add_contract')}
              onClick={toggleShow}
              sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                width: 64,
                height: 64,
                fontSize: 32,
              }}
              disabled={!(assets && assets.length > 0 && concierges && concierges.length > 0)}
            >
              <AddIcon />
            </Fab>
          )}
        </>
      )}

      <Dialog open={basicModal} onClose={toggleShow} fullWidth maxWidth="sm">
        <DialogTitle sx={{ backgroundColor: '#f5f5f5' }}>
          {t('contract_request')}
        </DialogTitle>
        <DialogContent>
          <ContractForm user={user} apply={handleNewContract} toggleShow={toggleShow} assets={assets} concierges={concierges} />
        </DialogContent>
      </Dialog>

      <LoadingModal open={isLoading} />
      <ConfirmDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={() => handleDeleteContract(contractIdToDelete, 'non-active')}
        message={t('confirm_contract_deletion')}
      />
    </Container>
  );
}
