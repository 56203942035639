import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getConcierges, getAssetsForConcierge, createReview } from '../../api';
import { useNotification } from '../../context/NotificationContext'; // Import notification context

const ReviewRequestForm = ({ onClose }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [concierges, setConcierges] = useState([]);
  const [assets, setAssets] = useState([]);
  const [selectedConcierge, setSelectedConcierge] = useState('');
  const [selectedAsset, setSelectedAsset] = useState('');
  const [loadingConcierges, setLoadingConcierges] = useState(true);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Fetch the list of concierges on component mount
  useEffect(() => {
    const fetchConcierges = async () => {
      try {
        const response = await getConcierges();
        setConcierges(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingConcierges(false);
      }
    };
    fetchConcierges();
  }, []);

  // Fetch assets when a concierge is selected
  const handleConciergeChange = async (event) => {
    setSelectedConcierge(event.target.value);
    setSelectedAsset(''); // Reset asset selection when concierge changes
    setLoadingAssets(true);
    try {
      const response = await getAssetsForConcierge(event.target.value);
      setAssets(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAssets(false);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await createReview({
        concierge_id: selectedConcierge,
        asset_id: selectedAsset,
      });
      showNotification(t('Review request sent successfully'), 'success');
      onClose(); // Close the form after successful submission
    } catch (error) {
      console.error(error);
      showNotification(t('Failed to send review request'), 'error');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 500, mx: 'auto', p: 2, borderRadius: 2, boxShadow: 3, bgcolor: 'background.paper' }}>
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel>{t('Concierge')}</InputLabel>
        <Select
          value={selectedConcierge}
          onChange={handleConciergeChange}
          label={t('Concierge')}
          disabled={loadingConcierges}
        >
          {loadingConcierges ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          ) : (
            concierges.map((concierge) => (
              <MenuItem key={concierge.id} value={concierge.id}>
                {concierge.name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>

      <FormControl fullWidth variant="outlined" margin="normal" disabled={!selectedConcierge || loadingAssets}>
        <InputLabel>{t('Asset')}</InputLabel>
        <Select
          value={selectedAsset}
          onChange={(e) => setSelectedAsset(e.target.value)}
          label={t('Asset')}
        >
          {loadingAssets ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          ) : (
            assets.map((asset) => (
              <MenuItem key={asset.id} value={asset.id}>
                {asset.name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>

      <Box mt={3} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedConcierge || !selectedAsset || submitting}
        >
          {submitting ? <CircularProgress size={24} color="inherit" /> : t('Submit Review Request')}
        </Button>
      </Box>
    </Box>
  );
};

export default ReviewRequestForm;
