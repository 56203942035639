import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, CircularProgress, Autocomplete } from '@mui/material';
import { configBrands, configModels } from '../../../../api';

const DetailsAuto = forwardRef(({ data, user, t, onValidSubmit, cat }, ref) => {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [loadingModels, setLoadingModels] = useState(false);

  const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
    defaultValues: {
      brand: data?.brand || '',
      model: data?.model || '',
      release_year: data?.release_year || '',
    },
    mode: "onChange"
  });

  useEffect(() => {
    if (data) {
      for (const key in data) {
        setValue(key, data[key] ?? '');
      }
    }
  }, [data, setValue]);

  useImperativeHandle(ref, () => ({
    submitForm: () => handleSubmit(handleFormSubmit)(),
  }));

  useEffect(() => {
    // Fetch car brands
    configBrands()
      .then(response => {
        setBrands(response.data);
        const initialBrand = response.data.find(brand => brand.id === getValues("brand"));
        if (initialBrand) {
          setSelectedBrand(initialBrand);
          handleBrandChange(initialBrand, true);
        }
      })
      .catch(error => console.error('Error fetching car brands:', error));
  }, [getValues]);

  const handleFormSubmit = (formData) => {
    const brandName = brands.find(brand => brand.id === formData.brand)?.name || formData.brand;
    onValidSubmit({
      ...formData,
      brand: brandName,
      "estimation": 0,
    });
    console.log("Form submitted", formData);
  };

  const handleBrandChange = async (brand, initial = false) => {
    setSelectedBrand(brand);
    setValue('brand', brand.id);
    setModels([]);
    setLoadingModels(true);

    try {
      const response = await configModels(brand.id);
      setModels(response.data);
    } catch (error) {
      console.error('Error fetching car models:', error);
    } finally {
      setLoadingModels(false);
    }

    if (initial && data.model) {
      setValue('model', data.model);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Controller
        name="brand"
        control={control}
        rules={{ required: t('brand') }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={brands}
            value={selectedBrand}
            getOptionLabel={(option) => option.name || ''}
            onChange={(e, newValue) => {
              handleBrandChange(newValue || {});
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('brand')}
                margin="normal"
                fullWidth
                helperText={errors.brand ? errors.brand.message : ''}
              />
            )}
          />
        )}
      />

      <Controller
        name="model"
        control={control}
        rules={{ required: t('Model is required') }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={models}
            getOptionLabel={(option) => option || ''}
            onChange={(e, newValue) => setValue('model', newValue || '')}
            loading={loadingModels}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Model')}
                margin="normal"
                fullWidth
                error={!!errors.model}
                helperText={errors.model ? errors.model.message : ''}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingModels ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      />

      <Controller
        name="release_year"
        control={control}
        rules={{ required: t('Year is required') }}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('release_year')}
            type="number"
            margin="normal"
            fullWidth
            error={!!errors.release_year}
            helperText={errors.release_year ? errors.release_year.message : ''}
          />
        )}
      />
    </Box>
  );
});

export default DetailsAuto;
