import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import { deleteUser, getConcierge, updateUser, configCountries } from '../../api';
import { useClient } from 'react-supabase';
import enFlag from '../../resources/en_flag.svg';
import frFlag from '../../resources/fr_flag.svg';
import { useNotification } from '../../context/NotificationContext';
import i18next, { changeLanguage } from 'i18next';
import {
  Box,
  CircularProgress,
  Container,
  Tabs,
  Tab, Autocomplete,
  Button,
  Typography,
  Divider,
  Avatar,
  FormControlLabel,
  Switch,
  TextField,
  IconButton,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import EuroIcon from '@mui/icons-material/Euro';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import LanguageIcon from '@mui/icons-material/Language';
import BarChartIcon from '@mui/icons-material/BarChart';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

export default function Profile() {
  const navigate = useNavigate();
  const { user, loading, refreshUser } = useContext(UserContext);
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [activeTab, setActiveTab] = useState(0);
  const client = useClient();
  const [userEmail, setUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const defaultCountry = user.country;
  const [countriesList, setCountries] = useState([defaultCountry]);
  const [concierge, setConcierge] = useState(null);
  const [preferences, setPreferences] = useState(user.preferences || {});
  const [localPreferences, setLocalPreferences] = useState(preferences);
  const [localLanguage, setLocalLanguage] = useState(user.language);
  const [localName, setLocalName] = useState(user?.name);
  const [localSurname, setLocalSurname] = useState(user?.surname);
  const [localPhone, setLocalPhone] = useState(user?.phone);
  const [localCountry, setLocalCountry] = useState(user?.country);
  const [basicModal, setBasicModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);

  useEffect(() => {
    configCountries().then(response => {
      setCountries(response.data);
    });
  }, []);

  const handleInputChange = (key, value) => {
    switch (key) {
      case 'name':
        setLocalName(value);
        break;
      case 'surname':
        setLocalSurname(value);
        break;
      case 'phone':
        setLocalPhone(value);
        break;
      case 'country':
        setLocalCountry(value);
        break;
      default:
        const updatedPreferences = { ...localPreferences, [key]: value };
        setLocalPreferences(updatedPreferences);
    }
    setIsDirty(true);
  };

  useEffect(() => {
    if (user) {
      // Initialize preferences and language when user data is available
      setLocalPreferences({
        estimations: user.preferences?.estimations || false,
        notifications: user.preferences?.notifications || false,
        automatic_validation_days: user.preferences?.automatic_validation_days || 0,
      });
      setLocalLanguage(user.language || 'en');
    }
  }, [user]);

  const handleLanguageChange = () => {
    const newLanguage = localLanguage === 'en' ? 'fr' : 'en';
    setLocalLanguage(newLanguage);
    setIsDirty(true);
  };
  useEffect(() => {
    const fetchConciergeDetails = async () => {
      if (user.role === 'PRO') {
        try {
          const response = await getConcierge(user.concierge_id);
          setConcierge(response.data);
        } catch (error) {
          console.error('Failed to fetch concierge details:', error);
        }
      }
    };

    // Fetching user session email if available
    const session = client.auth.session();
    if (session && session.user) {
      setUserEmail(session.user.email);
    }

    if (user) {
      fetchConciergeDetails(); // Ensure function is called if user is present
    } else {
      refreshUser();
    }
  }, [user, refreshUser]);

  if (loading || !user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleApplyChanges = async () => {
    try {
      // Construct the data object to update the user information and preferences

      const data = {
        id: user.id,
        name: localName,
        surname: localSurname,
        email: user.email,
        phone: localPhone,
        currency: user.currency,
        language: localLanguage,
        country: localCountry,
        preferences: {
          ...user.preferences,
          estimations: Boolean(localPreferences.estimations),
          notifications: Boolean(localPreferences.notifications),
          automatic_validation_days: parseInt(localPreferences.automatic_validation_days, 10),
        },
      };
      console.log('Updating user data:', data);
      // Call the updateUser API to update the backend

      await updateUser(data);
      showNotification(t('profile_edit_successful'), 'success');
      refreshUser(); // Refresh user to update with new preferences
      changeLanguage(localLanguage);
      setIsDirty(false);
    } catch (error) {
      console.error('Failed to update user', error);
      showNotification(t('profile_edit_failed'), 'error');
    }
  };
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(i18next.language, options);
  };

  const handleCloseAccount = async () => {
    try {
      setIsLoading(true);
      await deleteUser();
      showNotification(t('alert_account_deleted'));
      navigate('/logout');
      setIsLoading(false);
      // logout

      navigate('/login');
    } catch (error) {
      console.error('Failed to delete account', error);
      setIsLoading(false);
      showNotification('Failed to delete account');
    }
  };

  const handleClosureConfirmation = () => {
    const userConfirmation = prompt(t('confirm_account_closure'));
    if (userConfirmation === 'CONFIRM') {
      handleCloseAccount();
    } else {
      showNotification("Account closure cancelled.");
    }
  };

  return (
    <Container maxWidth="md" sx={{ position: 'relative', minHeight: '100vh', pb: 10 }}>
      {/* Header Section with Editable Avatar */}
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <Avatar
          sx={{
            width: 100,
            height: 100,
            border: '2px solid #eee',
            boxShadow: 2,
            cursor: 'pointer',
            ':hover': { borderColor: 'primary.main' },
          }}
          src={user.avatarUrl || '/default-avatar.png'}
          onClick={() => toggleShow()}
        />
        <IconButton
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 140,
            bgcolor: 'background.paper',
          }}
          onClick={() => toggleShow()}
        >

        </IconButton>
        <Box ml={2} textAlign="center">
          <Typography variant="h5">{`${user.surname} ${user.name}`}</Typography>
        </Box>
      </Box>

      {/* Tabs Section with Animated Transitions */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        TabIndicatorProps={{
          style: { transition: 'transform 0.3s ease', backgroundColor: '#1976d2' },
        }}
      >
        <Tab label={t('details')} />
        <Tab label={t('preferences')} />
        {user.role === 'PRO' && concierge && <Tab label={t('company')} />}
      </Tabs>

      {/* Tab Content */}
      <Box>
        <Box
          sx={{
            transition: 'opacity 0.5s',
            opacity: 1,
            mt: 1,
          }}
        >
          {activeTab === 0 && (
            <Box p={2}>
              <Box display="flex" alignItems="center">
                <PersonIcon color="primary" sx={{ fontSize: 24, mr: 2 }} />
                <Box display="flex" flexDirection="column" sx={{ mr: 3 }}>
                  <Typography variant="caption" color="textSecondary">{t('name')}</Typography>
                  <Typography variant="body1" contentEditable onBlur={(e) => handleInputChange('name', e.target.textContent)}>
                    {user.name || t('no_name')}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography variant="caption" color="textSecondary">{t('surname')}</Typography>
                  <Typography variant="body1" contentEditable onBlur={(e) => handleInputChange('surname', e.target.textContent)}>
                    {user.surname || t('no_surname')}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 1 }} />

              <Box display="flex" alignItems="center" mb={1}>
                <MailIcon color="info" sx={{ fontSize: 24, mr: 2 }} />
                <Box>
                  <Typography variant="caption" color="textSecondary">{t('email')}</Typography>
                  <Typography variant="body1">
                    {user.email || t('no_email')}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 1 }} />

              <Box display="flex" alignItems="center" mb={1}>
                <PhoneIcon color="secondary" sx={{ fontSize: 24, mr: 2 }} />
                <Box flex={1}>
                  <Typography variant="caption" color="textSecondary">{t('phone')}</Typography>
                  <TextField
                    variant="standard"
                    type="number"
                    value={localPhone || ''}
                    onChange={(e) => handleInputChange('phone', e.target.value)}
                    placeholder={t('enter_phone_number')}
                    InputProps={{
                      style: {
                        fontSize: '1rem', // equivalent to body1 in Typography
                        lineHeight: '1.5', // similar line height
                      },
                      disableUnderline: true,
                    }}
                    sx={{ width: '100%' }}
                  />
                </Box>
              </Box>
              <Divider sx={{ my: 1 }} />

              {/* Country Field using Improved Autocomplete */}
              <Box display="flex" alignItems="center" mb={2}>
                <Box flex={1}>
                  <Autocomplete
                    disabled={user.role==="PRO"}
                    options={countriesList}
                    getOptionLabel={(option) => option || ""}
                    value={localPreferences.country || user.country || ""}
                    onChange={(event, value) => handleInputChange('country', value || "")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <Box display="flex" alignItems="center">
                            <LocationOnIcon sx={{ fontSize: 18, color: 'error.main', mr: 1 }} />
                            {t('country')}
                          </Box>
                        }
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          )}

          {activeTab === 1 && (
            <Box p={2}>
              <Box display="flex" alignItems="center" mb={2}>
                <LanguageIcon color="info" sx={{ fontSize: 24, mr: 1 }} />
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="caption" color="textSecondary">{t('language')}</Typography>
                  <Button onClick={handleLanguageChange}>
                    <img className="language-flag" src={localLanguage === 'en' ? enFlag : frFlag} alt="Language Flag" />
                  </Button>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ mx: 2, height: 40 }} />
                <EuroIcon color="primary" sx={{ fontSize: 24, mr: 1 }} />
                <Box>
                  <Typography variant="caption" color="textSecondary">{t('currency')}</Typography>
                  <Typography variant="body1">{user.currency}</Typography>
                </Box>
              </Box>

              <Divider sx={{ my: 1 }} />

           {user.role !== "PRO" &&  <Box display="flex" alignItems="center" mb={2}>
                <BarChartIcon color="action" sx={{ fontSize: 24, mr: 2 }} />
                <Typography variant="body1" color="textSecondary" sx={{ flexGrow: 1 }}>{t('estimations')}</Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={localPreferences.estimations || false}
                      onChange={(e) => handleInputChange('estimations', e.target.checked)}
                    />
                  }
                />
              </Box>}

              <Divider sx={{ my: 1 }} />

              <Box display="flex" alignItems="center" mb={2}>
                <NotificationsActiveIcon color="warning" sx={{ fontSize: 24, mr: 2 }} />
                <Typography variant="body1" color="textSecondary" sx={{ flexGrow: 1 }}>{t('notifications')}</Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={localPreferences.notifications || false}
                      onChange={(e) => handleInputChange('notifications', e.target.checked)}
                    />
                  }
                />
              </Box>

              <Divider sx={{ my: 1 }} />

              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="body1" sx={{ flexGrow: 1 }}>{t('automatic_validation_days')}</Typography>
                <TextField
                  type="number"
                  value={localPreferences.automatic_validation_days || ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (value > 0) {
                      handleInputChange('automatic_validation_days', value);
                    } else {
                      handleInputChange('automatic_validation_days', '');
                      showNotification(t('positive_number_required'), 'error');
                    }
                  }}
                  inputProps={{ min: 1 }}
                  sx={{ width: '60px' }}
                />
              </Box>
            </Box>
          )}

          {activeTab === 2 && user.role === 'PRO' && concierge && (
  <Box display="flex" flexDirection="column" p={2}>

    <Box display="flex" flexDirection="column" mb={2}>
      <Typography variant="body2" color="textSecondary">{t('name')}</Typography>
      <Typography variant="body1" fontWeight="medium">{concierge.name || t('not_available')}</Typography>
    </Box>

    <Box display="flex" flexDirection="column" mb={2}>
      <Typography variant="body2" color="textSecondary">{t('code')}</Typography>
      <Typography variant="body1" fontWeight="medium">{concierge.code || t('not_available')}</Typography>
    </Box>

    <Box display="flex" flexDirection="column" mb={2}>
      <Typography variant="body2" color="textSecondary">{t('country')}</Typography>
      <Typography variant="body1" fontWeight="medium">{concierge.country || t('not_available')}</Typography>
    </Box>

    <Box display="flex" flexDirection="column" mb={2}>
      <Typography variant="body2" color="textSecondary">{t('city')}</Typography>
      <Typography variant="body1" fontWeight="medium">{concierge.city || t('not_available')}</Typography>
    </Box>

    <Box display="flex" flexDirection="column" mb={2}>
      <Typography variant="body2" color="textSecondary">{t('fees')}</Typography>
      <Typography variant="body1" fontWeight="medium">{concierge.fees ? `${concierge.fees}%` : t('not_available')}</Typography>
    </Box>

    <Box display="flex" flexDirection="column" mb={2}>
      <Typography variant="body2" color="textSecondary">{t('category')}</Typography>
      <Typography variant="body1" fontWeight="medium">{t(concierge.category) || t('not_available')}</Typography>
    </Box>

    <Box display="flex" flexDirection="column" mb={2}>
      <Typography variant="body2" color="textSecondary">{t('contact')}</Typography>
      <Typography variant="body1" fontWeight="medium">
        {concierge.contact ? (
          <>
            {concierge.contact.phone && <div>{`${t('phone')}: ${concierge.contact.phone}`}</div>}
            {concierge.contact.email && <div>{`${t('email')}: ${concierge.contact.email}`}</div>}
          </>
        ) : t('not_available')}
      </Typography>
    </Box>

    <Box display="flex" flexDirection="column" mb={2}>
      <Typography variant="body2" color="textSecondary">{t('address')}</Typography>
      <Typography variant="body1" fontWeight="medium">{concierge.address || t('not_available')}</Typography>
    </Box>

    <Box display="flex" flexDirection="column" mb={2}>
      <Typography variant="body2" color="textSecondary">{t('description')}</Typography>
      <Typography variant="body1" fontWeight="medium">{concierge.description || t('not_available')}</Typography>
    </Box>

    <Box display="flex" flexDirection="column" mb={2}>
      <Typography variant="body2" color="textSecondary">{t('currency')}</Typography>
      <Typography variant="body1" fontWeight="medium">{concierge.currency || t('not_available')}</Typography>
    </Box>
  </Box>
)}
        </Box>

        {/* Profile Abonnement */}
        <Divider />
        <Box sx={{  pr: 2, pl: 2, width: '100%' }}>
        <Divider />


          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="body2" color="textSecondary">
              {t('account_type')}
            </Typography>
            <Typography variant="body2" fontWeight="medium">
              {t(user.role)}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color="textSecondary">
              {t('subscription')}
            </Typography>
            <Typography variant="body2" fontWeight="medium">
              {user.plan_expiry_date ? formatDate(user.plan_expiry_date) : t('free')}
            </Typography>
          </Box>
        </Box>


        {/* Apply Button */}
        <Button
          variant="contained"
          onClick={handleApplyChanges}
          disabled={!isDirty}
          sx={{
            width: '100%',
            mt: 2,
            background: isDirty ? 'linear-gradient(45deg, #2196F3, #21CBF3)' : '#ccc',
            ':hover': isDirty ? { boxShadow: '0px 0px 10px rgba(33, 203, 243, 0.6)' } : {},
          }}
        >
          {t('update')}
        </Button>
      </Box>
      <Divider sx={{ my: 3 }} />
      <Box mt={2} display="flex" justifyContent="center">
        <Button
          variant="contained"
          startIcon={<WarningIcon />}
          color="error"
          onClick={handleClosureConfirmation}
          sx={{
            width: '100%'
          }}
        >
          {t('close_account')}
        </Button>
      </Box>
    </Container>
  );
}
