import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Chip,
  Divider,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardToInbox from '@mui/icons-material/CheckCircle';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { approvePendingTransaction, rejectPendingTransaction, submitPendingTransaction, deletePendingTransaction } from '../api'
import { useNotification } from '../context/NotificationContext';
import { currencySymbol } from '../utils';

const TransactionDetails = ({ detail, user, partners }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1"><strong>{t('type')}:</strong> {t(detail.type)}</Typography>
      <Typography variant="subtitle1"><strong>{t('title')}:</strong> {detail.title}</Typography>
      <Typography variant="subtitle1"><strong>{t('category')}:</strong> {t(detail.category)}</Typography>
      <Typography variant="subtitle1"><strong>{t('amount')}:</strong> {detail.amount} {currencySymbol(user.currency)}</Typography>
      {detail.tags && <Typography variant="subtitle1"><strong>{t('tags')}:</strong> {detail.tags}</Typography>}
      {detail.partner_id && (
        <Typography variant="subtitle1"><strong>{t('partner')}:</strong> {partners.find(p => p.id === detail.partner_id)?.name}</Typography>
      )}
      {detail.occupancy && <Typography variant="subtitle1"><strong>{t('occupancy')}:</strong> {detail.occupancy}</Typography>}
      {detail.notes && <Typography variant="subtitle1"><strong>{t('notes')}:</strong> {detail.notes}</Typography>}
    </Box>
  );
};

const PendingTransactionList = ({
  user,
  transactions = [],
  assets = [],
  partners = [],
  refresh
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();

  // State to handle transactions actions
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  // Mutation hooks for each transaction action
  const approveTransactionMutation = useMutation(approvePendingTransaction, {
    onSuccess: () => {
      showNotification(t('transaction_approved_successfully'), 'success');
      queryClient.invalidateQueries('pending_transactions');
      refresh();
    },
    onError: (error) => {
      showNotification(t('transaction_operation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    },
  });

  const rejectTransactionMutation = useMutation(rejectPendingTransaction, {
    onSuccess: () => {
      showNotification(t('transaction_rejected_successfully'), 'success');
      queryClient.invalidateQueries('pending_transactions');
      refresh();
    },
    onError: (error) => {
      showNotification(t('transaction_operation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    },
  });

  const submitTransactionMutation = useMutation(submitPendingTransaction, {
    onSuccess: () => {
      showNotification(t('transaction_submitted_successfully'), 'success');
      queryClient.invalidateQueries('pending_transactions');
      refresh();
    },
    onError: (error) => {
      showNotification(t('transaction_submission_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    },
  });

  const deleteTransactionMutation = useMutation(deletePendingTransaction, {
    onSuccess: () => {
      showNotification(t('transaction_deleted_successfully'), 'success');
      queryClient.invalidateQueries('pending_transactions');
      refresh();
    },
    onError: (error) => {
      showNotification(t('transaction_operation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    },
  });

  // Define status colors and labels
  const statusColors = {
    DRAFT: 'info',
    SUBMITTED: 'primary',
    VALIDATED: 'success',
    REJECTED: 'error',
    REFUSED: 'warning',
    PENDING: 'default',
  };
  const statusLabels = {
    DRAFT: t('draft'),
    SUBMITTED: t('submitted'),
    VALIDATED: t('validated'),
    REJECTED: t('rejected'),
    REFUSED: t('refused'),
    PENDING: t('pending'),
  };

  // Handlers for each action
  const handleApproveTransaction = (transactionId) => {
    approveTransactionMutation.mutate({ transaction_id: transactionId });
  };

  const handleRejectTransaction = (transactionId) => {
    rejectTransactionMutation.mutate({ transaction_id: transactionId });
  };

  const handleSubmitTransaction = (transactionId) => {
    submitTransactionMutation.mutate({ transaction_id: transactionId });
  };

  const handleDeleteTransaction = (transactionId) => {
    deleteTransactionMutation.mutate({ transaction_id: transactionId });
  };

  return (
    <Box my={3}>
      {transactions.length > 0 ? (
        transactions.map((transaction) => {
          const asset = assets.find(a => a.id === transaction.asset_id);
          const isPRO = user.role === 'PRO';
          const isDraft = transaction.status === 'DRAFT';
          const isRejected = transaction.status === 'REJECTED';
          const isSubmitted = transaction.status === 'SUBMITTED';

          return (
            <Card key={transaction.id} sx={{ my: 2 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="subtitle1"><strong>{t('asset')}:</strong> {asset?.name || t('unknown')}</Typography>
                    <Typography variant="subtitle1"><strong>{t('date')}:</strong> {new Date(transaction.date).toLocaleDateString()}</Typography>
                    {transaction.repeat_until && (
                      <Typography variant="subtitle1"><strong>{t('repeat_until')}:</strong> {new Date(transaction.repeat_until).toLocaleDateString()}</Typography>
                    )}
                    {transaction.notes && (
                      <Typography variant="subtitle1"><strong>{t('notes')}:</strong> {transaction.notes}</Typography>
                    )}
                  </Box>
                  <Chip
                    label={statusLabels[transaction.status]}
                    color={statusColors[transaction.status]}
                    variant="outlined"
                    sx={{ ml: 1 }}
                  />
                </Box>
                <Divider sx={{ my: 1 }} />
                {transaction.details?.map((detail, index) => (
                  <TransactionDetails key={index} detail={detail} user={user} partners={partners} />
                ))}
              </CardContent>
              <CardActions>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Box sx={{ display: 'flex' }}>
                    {isPRO && isDraft && (
                      <Button
                        size="small"
                        color="primary"
                        startIcon={<ForwardToInbox />}
                        onClick={() => handleSubmitTransaction(transaction.id)}
                      >
                        {t('submit')}
                      </Button>
                    )}
                    {isPRO && (isDraft || isRejected || isSubmitted) && (
                      <Button
                        size="small"
                        color="secondary"
                        startIcon={<EditIcon />}
                        onClick={() => setSelectedTransactionId(transaction.id)}
                      >
                        {t('edit')}
                      </Button>
                    )}
                  </Box>

                  {isPRO && (isDraft || isRejected) && (
                    <Button
                      size="small"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteTransaction(transaction.id)}
                    >
                      {t('delete')}
                    </Button>
                  )}

                  {user.role === 'INDIVIDUAL' && isSubmitted && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Button
                        size="small"
                        color="success"
                        startIcon={<DoneIcon />}
                        onClick={() => handleApproveTransaction(transaction.id)}
                      >
                        {t('approve')}
                      </Button>
                      <Button
                        size="small"
                        color="warning"
                        startIcon={<ThumbDownIcon />}
                        onClick={() => handleRejectTransaction(transaction.id)}
                      >
                        {t('reject')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </CardActions>
            </Card>
          );
        })
      ) : (
        <Typography variant="body2">{t('no_transaction')}</Typography>
      )}
    </Box>
  );
};

export default PendingTransactionList;
