import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Card, CardActionArea, CardContent, CardMedia, CircularProgress, Box } from '@mui/material';
import { configAssetTypes } from '../../../../api';
import { getCategoryIcon, preloadImages } from '../../../../utils/Avatar';

const AssetTypeSelector = memo(({ onSelect }) => {
  const { t } = useTranslation();
  const [assetTypes, setTypes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    configAssetTypes()
      .then(response => {
        setTypes(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setError('Failed to load asset types');
        setLoading(false);
      });
  }, []);

  // Load images on component mount
  useEffect(() => {
    preloadImages().catch(error => console.error('Error preloading images:', error));
  }, []);

  const handleTypeSelect = (type) => {
    onSelect(type);
  };

  const getImageSrc = (type) => localStorage.getItem(`image_${type}`);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Grid container spacing={2}>
      {assetTypes.map((type) => {
        const imageSrc = getImageSrc(type);
        return (
          <Grid item xs={6} sm={6} md={6} lg={6} key={type}>
            <Card
              sx={{
                height: 160,
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                '&:hover': { boxShadow: '0 6px 12px rgba(0,0,0,0.2)', transform: 'scale(1.02)' },
              }}
              onClick={() => handleTypeSelect(type)}
            >
              <CardActionArea sx={{ position: 'relative', height: '100%' }}>
                {imageSrc ? (
                  <CardMedia
                    component="img"
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    image={imageSrc}
                    alt={t(type)}
                  />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: 'gray',
                      backgroundColor: 'lightgray',
                    }}
                  >
                    {getCategoryIcon(type)}
                  </Box>
                )}
                <CardContent
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)',
                    width: '100%',
                    padding: 1,
                  }}
                >
                  <Typography gutterBottom variant="h5" color="white">
                    {t(type)}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
});

export default AssetTypeSelector;
