import React from 'react';
import { Box } from '@mui/material';
import MetricCard from '../../utils/MetricCard'; // Ensure this path is correct

const Metrics = ({ user, stats, t }) => {

  return (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            p: 0.3,
          }}>
            <MetricCard
              title={t('roi')}
              value={stats?.roi}
              tooltip={t('tooltip_roi')}
              size="h7"
              t={t}
            />
            <MetricCard
              title={t('irr')}
              value={stats?.irr}
              tooltip={t('tooltip_irr')}
              size="h7"
              t={t}
            />
            <MetricCard
              title={t('cashflow')}
              value={stats?.cashflow}
              tooltip={t('tooltip_cashflow')}
              size="h7"
              currency={user.currency}
              t={t}
              simplify={true}
            />
          </Box>
  );
};

export default Metrics;
