import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  CardMedia,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { preloadImagesAssetType } from '../utils/Avatar';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { getCategoryIcon } from '../utils/Avatar';
// Assuming getCategoryIcon is a utility function that returns an icon based on category
import { formatAmount } from '../utils';

const AssetCard = ({ asset, onDelete, onOpen, currency, readOnly=false}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));


  // Define styles
  const cardStyle = {
    display: 'flex',
    m: 2,
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0 4px 12px 0 ${theme.palette.shadow}`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  };

  const contentStyle = {
    display: 'flex',
    alignItems: 'center',
    p: 2,
    borderBottom: matchesMD ? 'none' : `1px solid ${theme.palette.divider}`,
  };

  const avatarStyle = {
    width: 56,
    height: 56,
    mr: 2,
    backgroundColor: theme.palette.primary.light,
    cursor: 'pointer',
  };

  const titleStyle = {
    fontWeight: 'bold',
  };

  const descriptionStyle = {
    color: theme.palette.text.secondary,
    mb: 1,
  };

  const cashStyle = {
    color: asset.cashflow >= 0 ? 'green' : 'red',
  };

  return (
    <Card sx={cardStyle}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <CardContent sx={contentStyle}>
        <Avatar sx={avatarStyle} onClick={() => navigate(`/assets/${asset.id}`)}>
{getCategoryIcon(asset.category)}
</Avatar>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" sx={titleStyle}>{asset.name}</Typography>
            <Typography variant="body2" sx={descriptionStyle}>{asset.description}</Typography>
            <Typography variant="body2" color="text.secondary">{t(asset.category)}</Typography>
            <Typography variant="body2">
              {t('value')}: {formatAmount(asset.value, currency)}
            </Typography>
            {['FINANCIAL_PRODUCT'].includes(asset.type) ? ( <div>
            <Typography variant="body2">{t('deposit')}: {formatAmount(asset.purchase_price, currency)}</Typography>
            <Typography variant="body2">
            {t('gains')}: <span style={cashStyle}>{formatAmount(asset.gains, currency)}</span>
          </Typography></div>
        ) : (
          <div>
            <Typography variant="body2">{t('purchase_price')}: {formatAmount(asset.purchase_price, currency)}</Typography>
            <Typography variant="body2">
              {t('cashflow')}: <span style={cashStyle}>{formatAmount(asset.cashflow, currency)}</span>
            </Typography>
          </div>

            )}


          </Box>
        </CardContent>
        <CardActions>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button size="small" startIcon={<OpenInNewIcon />} onClick={() => onOpen(asset.id)}>
            {t('open')}
          </Button>
          {!readOnly && <Button size="small" color="error" startIcon={<DeleteIcon />} onClick={() => onDelete(asset.id)}>
            {t('delete')}
          </Button>
          }
          </Box>
        </CardActions>
      </Box>
    </Card>
  );
};

export default AssetCard;
